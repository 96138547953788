import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactFlow, {
  addEdge,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
  Background,
  Panel,
  MiniMap,
  Controls,
} from "reactflow";
import "reactflow/dist/style.css";

import styles from "./Flow.module.scss";
import { toast } from "react-toastify";

import {
  nodeTypes,
  edgeOptions,
  edgeTypes,
} from "../CustomNodes/customProperties";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createJsonFromNodeData,
  createJsonSmdtoNodeEdges,
  NO_TRANSITION_EVENT_NAME,
} from "../../utils/jsonUtils";
import { FlowContext } from "../../store/flow-context";
import axios from "../../api/axios";
import { endpoints } from "../../api/endpoints";
import { socketEvents, TestFlow } from "./TestFlow";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Apis, botClosingApi } from "../../api";
import { Button, Typography } from "@mui/material";
import { Init } from "../Init/Init";
import { ROUTES } from "../../utils/constant";
import { useDispatch } from "react-redux";
import { createBot, getBotVersions, updateBot } from "../../routines";
import { DialogModal } from "../Modal/DialogModal";
import BotTamplates from "../BotTamplates/BotTamplates";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" {...props} />;
});
const connectionLineStyle = { stroke: "black" };

function getRandomNodeId() {
  return Math.floor(Math.random() * 100000);
}

const findUnvisitedNodes = (nodes, edges) => {
  const visited = new Set();
  const initialNode = nodes.find(node => node.data.isInitialNode === true);
  function dfs(nodeId) {
    visited.add(nodeId);
    const connectedEdges = edges.filter(edge => edge.source === nodeId);
    connectedEdges.forEach(edge => {
      if (!visited.has(edge.target)) {
        dfs(edge.target);
      }
    });
  }
  dfs(initialNode.id);
  const unvisitedNodes = nodes.filter(node => !visited.has(node.id));
  return unvisitedNodes;
}
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   maxWidth: 800,
//   maxHeight: 800,
//   background: "#e0e6ed",
//   border: "3px solid #192569",
//   borderRadius: "9px",
//   //boxShadow: 50,
//   padding: 15,
// };
function Flow() {
  const reactFlowInstance = useReactFlow();
  const dispatch = useDispatch();
  const flowContext = useContext(FlowContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [smdVersions, setSmdVersions] = useState([{ label: 'v: 1.0', value: 1 }])
  const [currentVersion, setCurrentVersion] = useState(1)
  const [variant, setVariant] = useState("plane");
  const [botInitInfo, setBotInitInfo] = useState({});
  const [isTestClicked, setIsTestClicked] = useState(false);
  const [botFirstMessage, setBotFirstMessage] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isAiGenerated, setIsAiGenerated] = useState(false);
  const [isChangeTemplate, setIsChangeTemplate] = useState(false)
  const handleCloseTestFlow = async (id) => {
    setIsTestClicked(false);
    await botClosingApi(id);
  };
  const state = useMemo(() => {
    return location.state || {}
  }, [location])

  const clearFlowDataHandler = () => {
    reactFlowInstance.setNodes([]);
    reactFlowInstance.setEdges([]);
    flowContext.clearEditFlowData();

  };
  const validateFlowData = () => {
    if (
      !flowContext.flowData.edges.length ||
      flowContext.flowData.nodes.length < 2
    ) {
      toast.error("Please add at least 2 connected nodes!");
      return false;
    }

    let edgesSourceSet = new Map();
    const edges = flowContext.flowData.edges;
    for (let edge of edges) {
      if (!edgesSourceSet.has(edge.source)) {
        edgesSourceSet.set(edge.source, { valid: !!edge.data.transition });
      } else {
        if (!edgesSourceSet.get(edge.source).valid || !edge.data.transition) {
          toast.error(
            'Please add a text using the "+" icon in case a node has more than 1 child'
          );
          return false;
        }
      }
    }
    const invalidContextNode = flowContext.flowData.nodes.find((node) => {
      return !node.data.stateName || !node.data.type || !node.data.message?.['he-male'].trim('') || !node.data.message?.['he-female'].trim('');
    });
    if (
      !!invalidContextNode ||
      !flowContext.flowData.nodes.length
      // || reactFlowInstance.toObject().nodes.length !== flowContext.flowData.nodes.length
    ) {
      toast.error("Please fill in all information on each node!!!!");
      return false;
    }
    const invalidTextNode = flowContext.flowData.nodes.find((node) => {
      return node.data.stateName === "Email" && node.data.type !== "Email"
    })
    if (invalidTextNode) {
      toast.error('The node name "Email" is only valid for nodes of type email!');
      return false;
    }

    const edgesObj = {};

    for (const edge of flowContext.flowData.edges) {
      const invalidEdge = flowContext.flowData.edges.find(
        (ed) =>
          ed.source === edge.source &&
          ed.target !== edge.target &&
          ((ed.transition && !edge.transition) ||
            (edge.transition && !ed.transition))
      );

      if (invalidEdge) {
        toast.error(
          "Make sure all options are provided for a state, where options are given"
        );
        return false;
      }
    }

    for (const node of flowContext.flowData.nodes) {
      const duplicateNodes = flowContext.flowData.nodes.find(
        (nde) =>
          nde.id !== node.id && nde.data.stateName === node.data.stateName
      );
      if (duplicateNodes) {
        toast.error(
          `Each node should have unique name! Name: "${node.data.stateName}" is used more than once`
        );
        return false;
      } else if (edgesObj[node.id]) {
        delete edgesObj[node.id];
      } else {
        edgesObj[node.id] = node;
      }
    }

    const duplicateTransitions = flowContext.flowData.edges.find((edge) => {
      return edge.data.isDuplicate === true;
    });

    const edgesFromSource = {}

    const hasNoTransitionText = flowContext.flowData.edges.find((edge) => {
      const source = edgesFromSource[edge.source]
      edgesFromSource[edge.source] = source ? source + 1 : 1;
      return !edge.data?.transition?.hebrew && edgesFromSource[edge.source] > 1;
    });

    if (hasNoTransitionText) {
      toast.error(
        "Please add an event"
      );
      return false;
    }

    if (!!duplicateTransitions) {
      toast.error(
        'Please modify the duplicate event "' +
        duplicateTransitions.data.transition +
        '"'
      );
      return false;
    }

    const unvisitedNodes = findUnvisitedNodes(flowContext.flowData.nodes, flowContext.flowData.edges)
    if (unvisitedNodes.length > 0) {
      toast.error(`Can't have disconnected nodes!`)
      return false
    }
    return true;
  };

  const getBotJson = () => {
    const botJson = createJsonFromNodeData({
      nodes: flowContext.flowData.nodes,
      edges: flowContext.flowData.edges,
      initInfo: botInitInfo,
    });
    return botJson;
  };

  const submitClickHandler = () => {
    if (!validateFlowData()) {
      return;
    }
    const botJson = getBotJson();
    dispatch(createBot.trigger({ smd: botJson }))
  };

  const getBotVersionHandler = async (version) => {
    const smdId = flowContext?.flowData?.smdId;
    const { smd } = await Apis.getBotVersions({
      smdId,
      version,
    });
    const { nodes, edges } = createJsonSmdtoNodeEdges(smd.smd);
    const flowData = {
      ...flowContext.flowData,
      nodes: nodes,
      edges: edges,
    };
    flowContext.setFlowData(flowData);
    setCurrentVersion(version)
  };

  const updateHandler = async () => {
    try {
      if (!validateFlowData()) {
        return;
      }
      const payload = {
        smd_id: flowContext.flowData.smdId,
        smd: getBotJson(),
        smd_name: state.name,
        description: state.description,
        avatar: state.avatar,
        taskType: "1" // to add a new version
      };
      dispatch(updateBot.trigger(payload))
      localStorage.removeItem('flowData')
    } catch (error) {
      toast.error(error.message);
    }
  };

  const testClickHandler = (event) => {
    if (!validateFlowData()) {
      return;
    }
    const authToken = localStorage.getItem("authToken");
    axios
      .post(endpoints.bot.test, getBotJson(), {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        const events = res.data.events.find(
          (evt) => evt === NO_TRANSITION_EVENT_NAME
        );
        setBotFirstMessage({
          message: res.data.message,
          events: events ? [] : res.data.events,
          type: socketEvents.BOT_REPLY,
          botId: res.data.bot_id,
        });
        setIsTestClicked(true);
      })
      .catch((err) => console.error(err));
  };

  // React flow logic for adding node on new edge creation
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(
    flowContext.flowData.nodes
  );

  const [edges, setEdges, onEdgesChange] = useEdgesState(
    flowContext.flowData.edges
  );

  const onConnect = useCallback(
    (params) => {

      if (params.source === params.target) {
        toast.error("Self pointing edges are not allowed");
        return;
      }
      params.id = params.source + params.target;
      const reverseEdgeExists = flowContext.flowData.edges.find(
        (edge) => edge.source === params.target && edge.target === params.source
      );
      params.data = { reverseEdgeExists: !!reverseEdgeExists, transition: { english: undefined, hebrew: undefined } };
      setEdges((eds) => addEdge(params, eds));
      const edgeExists = flowContext.flowData.edges.find(
        (edge) => edge.source === params.source && edge.target === params.target
      );
      !edgeExists && flowContext.addOrUpdateEdge(params);
    },
    [flowContext, setEdges]
  );

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains("react-flow__pane");
      if (targetIsPane) {
        const id = `${getRandomNodeId()}`;
        // Remove the wrapper bounds, in order to get the correct position
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
        const newNode = {
          id,
          type: "custom",
          data: {
            id,
          },
          position: reactFlowInstance.project({
            x: event.clientX - left - 75,
            y: event.clientY - top,
          }),
        };

        const newEdge = {
          style: {
            stroke: "white",
          },
          markerEnd: {
            type: "arrowclosed",
            width: "25px",
            height: "25px",
          },
          data: {
            transition: { english: undefined, hebrew: undefined },
          },
          type: "buttonedge",
          id: connectingNodeId.current + id,
          source: connectingNodeId.current,
          target: id,
        };
        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) => eds.concat(newEdge));
        flowContext.addOrUpdateEdge(newEdge);
        flowContext.addOrUpdateNode(newNode);
      }
    },
    [reactFlowInstance, setNodes, setEdges, flowContext]
  );

  const onNodeDrag = (_, node) => {
    flowContext.updateNodePosition(node.id, node.position.x, node.position.y);
  };

  const onNodeDelete = (nodes) => {
    flowContext.deleteNodeById(nodes[0]);
  };

  const onEdgesDelete = (edges) => {
    flowContext.deleteEdgeById(edges[0]);
  };

  useEffect(() => {
    if (state.smdVersions?.length) {
      const versions = state.smdVersions.map((version, i) => {
        return {
          label: `v: ${i + 1}.0`,
          value: version
        }
      })
      setSmdVersions(versions)
    }
  }, [state.smdVersions])

  useEffect(() => {
    if (state.currentSmdVersion) {
      setCurrentVersion(state.currentSmdVersion);
    }
  }, [state.currentSmdVersion])

  // Redirect to / if this component is loaded before init page
  useEffect(() => {
    if (!state.name) {
      navigate("/");
    } else {
      setIsPreviewMode(state.isPreview)
      setIsAiGenerated(state.isAiGenerated)
      setBotInitInfo(!!state.isPreview ? {} : state)
      reactFlowInstance.setNodes(flowContext.flowData.nodes);
      reactFlowInstance.setEdges(flowContext.flowData.edges);
    }

  }, [
    navigate,
    state,
    reactFlowInstance,
    flowContext.flowData.nodes,
    flowContext.flowData.edges,
  ]);

  useEffect(() => {
    let smdData = localStorage.getItem(`flowData`);
    if (smdData) {
      const { smd, smdId, platform } = JSON.parse(smdData);
      const { nodes, edges, } = createJsonSmdtoNodeEdges(smd);
      const flowData = {
        nodes: nodes,
        edges: edges,
        smdId: smdId,
        platform,
      };
      flowContext.setFlowData(flowData);
    }
    return () => {
      flowContext.clearFlowData();
    }
  }, []);

  return (
    <Typography
      ref={reactFlowWrapper}
      component={"div"}
      sx={{
        height: `calc( 100% + 15px)`,
        width: `calc( 100% + 32px)`,
        m: -2.1,
        border: "2px solid #3E3E3E80",
        // '& .react-flow__attribution':{
        //   display:'none'
        // }
      }}
    >
      <Dialog
        classes={{
          root: styles.testContainer,
        }}
        PaperProps={{
          style: {
            background: "transparent",
            margin: "0px",
            border: "0.5px solid #C8C8C8",
            borderRadius: "15px 15px 10px 10px",
          },
        }}
        BackdropProps={{ style: { background: "transparent" } }}
        keepMounted
        open={isTestClicked}
        TransitionComponent={Transition}
        sx={{
          height: 800,
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        {isTestClicked && (
          <TestFlow
            avatar={botInitInfo.avatar}
            showTestFlow={setIsTestClicked}
            botFirstMessage={botFirstMessage}
            runBotTitle="Test Your Bot"
            handleCloseTestFlow={handleCloseTestFlow}
            isTest
          />
        )}
      </Dialog>
      <ReactFlow
        edges={edges}
        nodes={nodes}
        defaultEdgeOptions={edgeOptions}
        style={{
          backgroundColor: "#C8C8C880",
        }}
        ButtonEdge
        nodeTypes={nodeTypes}
        connectionLineStyle={connectionLineStyle}
        edgeTypes={edgeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onNodeDrag={onNodeDrag}
        onNodesDelete={onNodeDelete}
        onEdgesDelete={onEdgesDelete}
        preventScrolling={true}
        disableKeyboardA11y={flowContext.isKeyboardDisabled}
        fitViewOptions={{ duration: 1500 }}
        fitView
        proOptions={{ hideAttribution: true }}
        deleteKeyCode=""
      >
        {variant !== "plane" && (
          <Background color="#6c6d70" variant={variant} />
        )}
        <MiniMap
          className={styles.MiniMap}
          zoomable
          pannable
          position="bottom-right"
        />
        <Controls className={styles.controls} position="bottom-left" />
        <Panel position="top-left" className={styles.buttonContainer}>
          {!isPreviewMode && state.canUpdate ?
            (<div className={styles.botNameContainer}>
              <span>
                <img className={styles.editAvatar} src={state.avatar} />
                {state.name}
                {"  "}
              </span>
              <button
                className={styles.editIcon}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  size="lg"
                  className={styles.editIcon}
                />
              </button>
            </div>)
            : (<div className={styles.botNameContainer}>
              <span>
                <img className={styles.editAvatar} src={state.avatar} />
                {state.name}
                {"  "}
              </span>
            </div>)}
          <DialogModal open={isModalOpen} onClose={() => setIsModalOpen(false)} visibleBackdrop={isPreviewMode} >
            <Init
              onClose={() => setIsModalOpen(false)}
              initialData={isPreviewMode ? undefined : {
                name: botInitInfo.name,
                description: botInitInfo.description,
                type: botInitInfo.type,
                avatar: botInitInfo.avatar,
              }}
              isEdit={!isPreviewMode}
              canUpdate={state.canUpdate}
            />
          </DialogModal>
          <BotTamplates open={isChangeTemplate} onClose={() => { setIsChangeTemplate(false) }} />
          <Typography
            component={"div"}
            sx={{
              display: "flex",
              gap: 1,
              height: "100%",
              width: "fit-content",
            }}
          >
            {!isPreviewMode && state.canUpdate && <FontAwesomeIcon
              icon={faRefresh}
              className={styles.clearFlow}
              size="lg"
              onClick={clearFlowDataHandler}
            />}
            <select
              className={styles.backgroundSelect}
              value={variant}
              name="type"
              onChange={(event) => {
                setVariant(event.target.value);
              }}
            >
              <option value={"plane"}>Plane</option>
              <option value={"dots"}>Dotted</option>
              <option value={"lines"}>Lines</option>
              <option value={"cross"}>Cross</option>
            </select>
            {state.canUpdate && <Button
              sx={{
                textTransform: "none",
                fontWeight: 600,
                background: "#089BAE",
                color: "#FCFCFC",
                "&:hover": {
                  backgroundColor: "#0a5e69",
                },
              }}
              onClick={testClickHandler}
            >
              Test
            </Button>}
            {!flowContext.flowData.smdId && !isPreviewMode && (
              <Button
                sx={{
                  textTransform: "none",
                  fontWeight: 600,
                  background: "#089BAE",
                  color: "#FCFCFC",
                  "&:hover": {
                    backgroundColor: "#0a5e69",
                  },
                }}
                onClick={submitClickHandler}
              >
                Submit
              </Button>
            )}
            {!!flowContext.flowData.smdId && !isPreviewMode && state.canUpdate && (
              <Button
                sx={{
                  background: "#089BAE",
                  color: "#FCFCFC",
                  textTransform: "none",
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: "#0a5e69",
                  },
                }}
                onClick={updateHandler}
              >
                Update
              </Button>
            )}
            {isPreviewMode && !isAiGenerated && <Button
              sx={{
                background: "#089BAE",
                color: "#FCFCFC",
                textTransform: "none",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#0a5e69",
                },
              }}
              onClick={() => { setIsChangeTemplate(true) }}
            >
              Change Template
            </Button>}
            {(isPreviewMode && isAiGenerated) && <Button
              sx={{
                background: "#089BAE",
                color: "#FCFCFC",
                textTransform: "none",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#0a5e69",
                },
              }}
              onClick={() => { setIsChangeTemplate(true) }}
            >
              Regenerate
            </Button>}
            {isPreviewMode && <Button
              sx={{
                background: "#089BAE",
                color: "#FCFCFC",
                textTransform: "none",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#0a5e69",
                },
              }}
              onClick={() => { setIsModalOpen(true) }}
            >
              Continue with this one
            </Button>}
            <select
              className={styles.backgroundSelect}
              value={currentVersion}
              name="type"
              onChange={(event) => {
                getBotVersionHandler(event.target.value);
              }}
            >
              {smdVersions.map(version => {
                return <option value={version.value}>{version.label}</option>
              })}
            </select>
          </Typography>
        </Panel>
      </ReactFlow>
    </Typography>
  );
}

export const BotFlow = () => (
  <ReactFlowProvider>
    <Flow />
  </ReactFlowProvider>
);
