import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Typography } from "@mui/material";
import styles from "./AddOnPage.module.scss";
import { convertToTitleCase } from "../../utils/helper";
import CategorizedAddons from "../../components/CategorizedAddons";
import { connect } from "react-redux";

const AddonList = ({ addonsList }) => {
  const [addOnList, setAddOnList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const categorizeAddons = (addOns) => {
    const categories = {};

    addOns.forEach((addOn) => {
      if (addOn.category) {
        const prevAddons = categories[addOn.category] || [];
        categories[addOn.category] = [...prevAddons, addOn];
      }
    });

    return Object.keys(categories).map((category) => ({
      type: convertToTitleCase(category),
      addOns: categories[category],
    }));
  };

  const fetchAddon = () => {
    const { activatedAddon, addons } = addonsList;
    const activatedSet = new Set([...activatedAddon]);
    const addOnsWithBadge = addons.map((addon) => {
      return {
        ...addon,
        isActivated: activatedSet.has(addon.addonName),
      };
    });
    const categorizedAddons = categorizeAddons(addOnsWithBadge);
    setAddOnList(categorizedAddons);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!!Object.keys(addonsList).length) {
      fetchAddon();
    }
  }, [addonsList]);

  return (
    <>
      <Typography
        sx={{
          font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
          letterSpacing: "0px",
          color: "#3E3E3E",
          mb: "2rem",
          fontSize: "1.5rem",
        }}
      >
        Integration Tools
      </Typography>
      {isLoading ? (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Typography>
      ) : (
        <div className={styles.container}>
          <Typography sx={{ mb: 3 }}>
            <CategorizedAddons categories={addOnList} />
          </Typography>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    addonsList: state.addonsList,
  };
};

export default connect(mapStateToProps)(AddonList);
