
import React from "react";
import './style.scss';
const FallbackMessages = ({ nodeInfo, setNodeInfo, activeMessageLang }) => {
    const onChange = (event) => {
        const fallbackMessages = nodeInfo.fallbacks;
        fallbackMessages[event.target.name][activeMessageLang] = event.target.value
        setNodeInfo({
            ...nodeInfo,
            fallbacks: fallbackMessages
        })
    }
    return <div className="fallbackContainer mt-3">

        {Object.keys(nodeInfo?.fallbacks).map((key, index) => {
            return <div className="fallBackMessageContainer" key={index}>
                <p className="fallback-type m-0">{`${index + 1}. ${key}`}</p>
                <textarea
                    name={key}
                    placeholder="Fallback message"
                    className="w-full h-auto m-0 p-1  border-2 hover:border-[#000] focus:border-[#089BAE] focus:border-2 resize-none rounded overflowY-auto"
                    value={nodeInfo.fallbacks[key][activeMessageLang]}
                    onChange={(onChange)}
                    onBlur={() => {
                        const trimMessages = nodeInfo.fallbacks[key];
                        for (let lang in trimMessages) {
                            trimMessages[lang] = trimMessages[lang].trim();
                        };
                        setNodeInfo((prev) => {
                            return {
                                ...prev,
                                fallbacks: {
                                    ...nodeInfo.fallbacks,
                                    [key]: trimMessages,
                                }
                            };
                        });
                    }}
                />
            </div>
        })}
    </div>

}

export default FallbackMessages;