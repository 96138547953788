import React, { useContext, useEffect, useState } from "react";
import { EdgeLabelRenderer, getBezierPath, BaseEdge } from "reactflow";
import { FlowContext } from "../../store/flow-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faCircleExclamation,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { BootstrapTooltip } from "../UI/Tooltip";

import styles from "./ButtonEdge.module.scss";
import { guards } from "../../utils/constant";
import { toast } from "react-toastify";
import { characterLength } from "../../utils/constant";
import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { DialogModal } from "../Modal/DialogModal";

const foreignObjectSize = 200;

export default function ButtonEdge(buttonEdgeData) {
  const {
    source,
    target,
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    id,
    style,
    markerEnd,
    data,
  } = buttonEdgeData;

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const flowContext = useContext(FlowContext);
  const [isShowEdgeButton, setIsShowEdgeButton] = useState(true);
  const [validEvent, setValidEvent] = useState(true);
  const [transitionEvent, setTransitionEvent] = useState(new Set([]));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventText, setEventText] = useState({
    english: data?.transition?.english || "",
    hebrew: data?.transition?.hebrew || "",
  });
  const [selectedLang, setSelectedLang] = useState("hebrew");
  const [isEditable, setIsEditableEvent] = useState(buttonEdgeData.data?.hasOwnProperty("hebrewEventsEditable") && !buttonEdgeData.data.hebrewEventsEditable)

  const [transitionText, setTransitionText] = useState(data?.transition?.hebrew);

  const handleEventEdit = () => {
    setEventText(data.transition);

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedLang("hebrew");
    setIsModalOpen(false);
  };

  const handleTextChange = (event) => {
    setEventText((prevEventText) => ({
      ...prevEventText,
      [selectedLang]: event.target.value,
    }));
  };

  const handleLangSelect = (event, newLang) => {
    if (newLang !== null) {
      setSelectedLang(newLang);
    }
  };

  const onEdgeClick = (evt, id) => {
    if (
      buttonEdgeData.data.hasOwnProperty("isAddEvent") &&
      !buttonEdgeData?.isAddEvent
    ) {
      return toast.warning("Event adding is not allowed!");
    }
    setIsModalOpen(true);
  };

  const handleFocus = () => {
    // let arr = [];
    // flowContext.flowData.edges.forEach((edge) => {
    //   if (source === edge.source && !!edge.data.transition && edge.id !== id)
    //     arr.push(edge.data.transition);
    // });
    // setTransitionEvent(new Set([...arr]));
  };

  // const checkDuplicateEvent = (source,value) => {
  //     setValidEvent(true);
  //     flowContext.setHasDuplicateEvents(false);
  //     flowContext.flowData.edges.forEach((edgeData) => {
  //       if (edgeData.data.transition === value && edgeData.source === source) {
  //         setValidEvent(false);
  //         flowContext.setHasDuplicateEvents(true);
  //       }
  //     })
  //   }
  const transitionTextChangedHandler = () => {
    //checkDuplicateEvent(buttonEdgeData.source, event.target.value);

    const trimmedEventText = Object.fromEntries(
      Object.entries(eventText).map(([lang, value]) => [
        lang,
        (value || "").trim(),
      ])
    );

    setEventText(trimmedEventText);

    if (Object.values(trimmedEventText).some((trimmedValue) => trimmedValue.length > characterLength.maxEventNameLimit)) {
      toast.warning(
        `Maximum ${characterLength.maxEventNameLimit} characters are allowed for the event name`
      );
      return
    };
    if (Object.values(trimmedEventText).some((trimmedValue) => trimmedValue.length < 1)) {
      toast.warning(`Please provide both Hebrew and English event messages`);
      return
    };

    setTransitionText(trimmedEventText.hebrew);
    flowContext.addOrUpdateEdge({
      ...buttonEdgeData,
      data: {
        ...buttonEdgeData.data,
        transition: trimmedEventText,
        isDuplicate: transitionEvent.has(trimmedEventText.hebrew),
      },
    });

    setIsModalOpen(false);
  };

  const handleDeleteTransition = () => {
    if (buttonEdgeData.data?.hasOwnProperty("deleteEvents") && !buttonEdgeData.data?.deleteEvents) {
      return toast.warning("Event deleting is not allowed")
    }
    flowContext.addOrUpdateEdge({
      ...buttonEdgeData,
      data: {
        ...buttonEdgeData.data,
        transition: { english: undefined, hebrew: undefined },
        isDuplicate: false,
      },
    });
    setTransitionText("");
    setEventText({ english: undefined, hebrew: undefined });
  };

  const handleBlur = () => {
    if (transitionText.trim() === "") {
      flowContext.addOrUpdateEdge({
        ...buttonEdgeData,
        data: {
          ...buttonEdgeData.data,
          transition: { english: undefined, hebrew: undefined },
          isDuplicate: false,
        },
      });
    } else {
      flowContext.addOrUpdateEdge({
        ...buttonEdgeData,
        data: {
          ...buttonEdgeData.data,
          transition: {
            english: eventText.english,
            hebrew: eventText.hebrew,
          },
          isDuplicate: transitionEvent.has(transitionText.trim()),
        },
      });
      setTransitionText(transitionText.trim());
    }
  };
  const xCoordinate = data?.reverseEdgeExists
    ? labelX - foreignObjectSize / 5
    : labelX - foreignObjectSize / 1.5;

  const yCoordinate = data?.reverseEdgeExists
    ? labelY - foreignObjectSize / 5
    : labelY - foreignObjectSize / 1.5;

  useEffect(() => {
    const filteredNode = flowContext.flowData.nodes.find(
      (node) => node.id === source
    );
    if (!!filteredNode && filteredNode.data.type !== guards.isText) {
      setIsShowEdgeButton(false);
    } else {
      setIsShowEdgeButton(true);
    }
    let eventSet = new Set([]);
    flowContext.flowData.edges.forEach((edge) => {
      if (source === edge.source && edge.id !== id) {
        eventSet.add(edge.data?.transition?.hebrew);
      }
    });

    if (transitionText && eventSet.has(transitionText)) {
      setValidEvent(false);
    } else {
      setValidEvent(true);
    }
  }, [flowContext]);

  useEffect(() => {
    if (!isShowEdgeButton) {
      handleDeleteTransition(id);
    }
  }, [isShowEdgeButton]);

  useEffect(() => {
    flowContext.addOrUpdateEdge({
      ...buttonEdgeData,
      data: {
        ...buttonEdgeData.data,
        transition: {
          english: !!eventText?.english ? eventText.english.trim() : undefined,
          hebrew: !!eventText?.hebrew ? eventText.hebrew.trim() : undefined,
        },
        isDuplicate: !validEvent,
      },
    });
  }, [validEvent]);

  useEffect(() => {
    setIsEditableEvent(selectedLang === "hebrew" ?
      (buttonEdgeData.data?.hasOwnProperty("hebrewEventsEditable") && !buttonEdgeData.data.hebrewEventsEditable) :
      (buttonEdgeData.data?.hasOwnProperty("englishEventsEditable") && !buttonEdgeData.data.englishEventsEditable))
  }, [selectedLang])
  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <div
          //className={styles.edgeButtonForeignObject}
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            borderRadius: 5,
            fontSize: 20,
            fontWeight: 700,
            pointerEvents: "all",
            maxWidth: "170px",
          }}
          foreignObject
          className="nodrag nopan"
        >
          {(!!data?.transition?.english?.length &&
            !!data?.transition?.hebrew?.length) ||
            transitionText ? (
            <div
              className={`${validEvent ? styles.inputEvent : styles.eventContainerInvalid
                }`}
            >
              <TextField
                error={!validEvent}
                autoFocus
                hiddenLabel
                size="small"
                placeholder="Add Event"
                value={transitionText || ""}
                disabled
                onBlur={handleBlur}
                onFocus={handleFocus}
                InputProps={{
                  className: styles.inputEvent,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ marginRight: "-12px", marginLeft: "0px" }}
                    >
                      {!(buttonEdgeData.data?.hasOwnProperty("hebrewEventsEditable") &&
                        buttonEdgeData.data?.hasOwnProperty("englishEventsEditable") &&
                        !buttonEdgeData.data?.hebrewEventsEditable &&
                        !buttonEdgeData.data?.englishEventsEditable) && <BootstrapTooltip
                          title="Edit Event"
                          arrow
                          placement="right"
                        >
                          <FontAwesomeIcon
                            style={{
                              color: validEvent
                                ? "#089BAE"
                                : "#9b0c0cc9",
                            }}
                            sx={{
                              fontSize: 21,
                              cursor: "pointer",
                            }}
                            icon={faGear}
                            onClick={handleEventEdit}
                            edge="end"
                            cursor={"pointer"}
                          />
                        </BootstrapTooltip>}
                      {!(buttonEdgeData.data?.hasOwnProperty("deleteEvents") && !buttonEdgeData.data?.deleteEvents) && <BootstrapTooltip
                        title="Delete Event"
                        arrow
                        placement="right"
                      >
                        <DeleteOutlinedIcon
                          sx={{
                            color: validEvent ? "#089BAE" : "#9b0c0cc9",
                            fontSize: 21,
                            cursor: "pointer",
                          }}
                          onClick={handleDeleteTransition}
                          edge="end"
                        />
                      </BootstrapTooltip>}
                    </InputAdornment>
                  ),
                }}
              />
              {!validEvent && (
                <div className={styles.eventError}>
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  <span>Event already exist !!</span>
                </div>
              )}
            </div>
          ) : isShowEdgeButton && !(buttonEdgeData.data?.hasOwnProperty("hebrewEventsEditable") &&
            buttonEdgeData.data?.hasOwnProperty("englishEventsEditable") &&
            !buttonEdgeData.data?.hebrewEventsEditable &&
            !buttonEdgeData.data?.englishEventsEditable) ? (
            <BootstrapTooltip title="Add Event" arrow>
              <FontAwesomeIcon
                icon={faCirclePlus}
                onClick={(event) => onEdgeClick(event, id)}
              />
            </BootstrapTooltip>
          ) : (
            <></>
          )}
        </div>
        {/* </foreignObject> */}
        <DialogModal
          open={isModalOpen}
          onClose={handleCloseModal}
          visibleBackdrop={true}
        >
          <div className={styles.eventModalContainer}>
            <Typography
              sx={{
                color: "#089BAE",
                textAlign: "center",
              }}
            >
              Add event
            </Typography>
            <ToggleButtonGroup
              color="primary"
              exclusive
              sx={{
                marginTop: 2,
                "& .MuiToggleButton-root": {
                  border: "none",
                  "&.Mui-selected": {
                    background: "#089BAE",
                    color: "white",
                    "&:hover": {
                      background: "#148695",
                    },
                  },
                },
              }}
              value={selectedLang}
              onChange={handleLangSelect}
              aria-label="Platform"
            >
              <ToggleButton
                value="hebrew"
                sx={{ font: "normal normal normal 12px/16px Segoe UI" }}
              >
                עברית
              </ToggleButton>
              <ToggleButton
                value="english"
                sx={{
                  font: "normal normal normal 12px/16px Segoe UI",
                  textTransform: "capitalize",
                }}
              >
                English
              </ToggleButton>
            </ToggleButtonGroup>
            <TextField
              sx={{
                "& input::placeholder": {
                  font: "normal normal normal 12px/16px 'Graphie, Regular'",
                  letterSpacing: "0px",
                  color: "#3E3E3E",
                },
                "& fieldset": {
                  borderColor: "#C8C8C8",
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#089BAE",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#089BAE",
                },
                marginTop: 2, // Add margin at the top
              }}
              inputProps={{
                style: {
                  height: 18,
                  width: "100%",
                  padding: 9,
                  background: "white",
                  borderRadius: "5px",
                },
                placeholder: "Enter here......",
              }}
              value={eventText[selectedLang] || ""}
              onChange={handleTextChange}
              disabled={isEditable}
              helperText={isEditable ? 'This event is not editable' : "Write your event name"}
            />
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                mt: "30px",
                width: "100%",
                gap: "80px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  color: "#3E3E3E",
                  background: "#ECECEC 0% 0% no-repeat padding-box",
                  borderRadius: "5px",
                  font: "normal normal normal 12px/16px Segoe UI",
                  letterSpacing: "0px",
                  py: "8px",
                  px: "35px",
                  "&:hover": {
                    background: "#b4b5b5",
                  },
                }}
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  font: "normal normal normal 12px/16px Segoe UI",
                  color: "#FFFFFF",
                  background: "#089BAE",
                  borderRadius: "5px",
                  px: "35px",
                  py: "8px",
                  "&:hover": {
                    background: "#148695",
                  },
                }}
                onClick={transitionTextChangedHandler}
              >
                Done
              </Button>
            </Typography>
          </div>
        </DialogModal>
      </EdgeLabelRenderer>
    </>
  );
}
