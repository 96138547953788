import { handleActions } from "redux-actions";
import { getAddons } from "../routines";

const defaultState = {};

export const addonsReducer = handleActions(
  {
    [getAddons.SUCCESS]: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
  defaultState
);