import { Button, Typography, Link, IconButton } from "@mui/material";
import { borderRadius, padding } from "@mui/system";
import { Children, useEffect, useState } from "react";
import { NavigationList } from "../../../components/AppNavigation/NavigationLIst";

import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import { ROUTES } from "../../../utils/constant";
import largeLogoIcon from "./../../../Assets/largeLogoIcon.png";

import DashboardIcon from "@mui/icons-material/Dashboard";
import AnalyticsIcon from "@mui/icons-material/Analytics";
// import ExtensionIcon from "@mui/icons-material/Extension";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import PeopleIcon from "@mui/icons-material/People";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Avatar, createTheme, Menu, MenuItem, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import ApiIcon from '@mui/icons-material/Api';
import MenuList from "@mui/material/MenuList";
import { connect } from "react-redux";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const settings = ["Profile", "Logout"];

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,

  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  zIndex:1,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    marginLeft: `calc(${theme.spacing(10)} + 1px)`,
    width: `calc(100% - ${theme.spacing(10)})`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Sidebar = ({user}) => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleDrawer = () => {
    setOpen(!open);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleLoginLogout = () => {
    localStorage.removeItem('authToken');
    navigate(ROUTES.LOG_IN);
    handleCloseUserMenu();
  };

  const navigationList = [
    {
      name: "Dashboard",
      icon: <DashboardIcon sx={{ fontSize: "1.8rem" }} />,
      path: ROUTES.DASHBOARD,
      title: "Dashboard",
      iconColor: "white",
      show: true
    },
    {
      name: "Analytics",
      icon: <AnalyticsIcon sx={{ fontSize: "1.8rem" }} />,
      path: ROUTES.USER_ANALYTICS,
      title: "Analytics",
      iconColor: "white",
      show: process.env.REACT_APP_SHOW_ANALYTICS === 'true'
    },
    {
      name: "Users",
      icon: <PeopleIcon sx={{ fontSize: "1.8rem" }} />,
      path: ROUTES.USERS,
      title: "Users",
      iconColor: "white",
      show: process.env.REACT_APP_SHOW_USERS === 'true'
    },

    {
      name: "Add-On",
      icon: <LibraryAddIcon sx={{ fontSize: "1.8rem" }}/>,
      path: ROUTES.ADDON,
      title: "Add On",
      iconColor: "white",
      show: true
    },
    {
      name: "Chat",
      icon: <ChatBubbleIcon sx={{ fontSize: "1.8rem" }}/>,
      path: ROUTES.CHATS,
      title: "Chats",
      iconColor: "white",
      show: process.env.REACT_APP_SHOW_CHAT === 'true'
    },
  ];

  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background: "#fcfcfc00",
          opacity: 1,
          boxShadow: "0px 3px 6px #00000021",
          backdropFilter: "blur(20px)",
          height: 77,
          justifyContent: "center",
          zIndex: 1
        }}
      >
        {user && (
          <Toolbar>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="div"
                noWrap
                href="/"
                sx={{
                  // mt: 4,
                  font: "normal normal 600 24px/28px 'Graphie, SemiBold'",
                  letterSpacing: "0.8px",
                  color: "#3E3E3E",
                  opacity: 1,
                }}
              >
                Botifier
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!!user?.email && (
                <Typography
                  sx={{
                    border: "1px solid #089BAE",
                    borderRadius: 1,
                    background: "#FFFFFF",
                    width: 210,
                    p: 0.5,
                    height: "fit-content",
                    color: "#6E6E6E",
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: 1,
                  }}
                  onClick={handleOpenUserMenu}
                >
                  <Avatar alt="User" src={!!user.profileImg? user.profileImg: "/static/images/avatar/2.jpg"} />
                  <Typography sx={{ width: "100%" }}>{user.firstName + ' ' + user.lastName}</Typography>
                  {!!anchorElUser ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </Typography>
              )}
              <Menu
                sx={{ mt: "50px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuList
                  sx={{ width: 210, maxWidth: "100%", outline: "none" }}
                >
                  {/* <MenuItem>
                    <ListItemIcon>
                      <ContentPaste fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Paste</ListItemText>
                    <Typography variant="body2" color="text.secondary">
                      ⌘V
                    </Typography>
                  </MenuItem>
                  <Divider color='black'/>
                  <MenuItem>
                    <ListItemIcon>
                      <Cloud fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Web Clipboard</ListItemText>
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      navigate(ROUTES.PROFILE_PAGE);
                      handleCloseUserMenu()
                    }}
                  >
                    <ListItemText>Profile</ListItemText>
                    <Typography variant="body2">
                      <AccountCircleIcon
                        sx={{  color: "#6E6E6E" }}
                        fontSize="small"
                      />
                    </Typography>
                  </MenuItem>
                  {/* <MenuItem
                    onClick={() => {
                      navigate(ROUTES.GENERATE_API_KEYS);
                      handleCloseUserMenu()
                    }}
                  >
                    <ListItemText>Settings</ListItemText>
                    <Typography variant="body2">
                      <ApiIcon
                        sx={{  color: "#6E6E6E" }}
                        fontSize="small"
                      />
                    </Typography>
                  </MenuItem> */}
                  <MenuItem onClick={handleLoginLogout}>
                    <ListItemText>
                      {!!user.email ? "Log Out" : "Log In"}
                    </ListItemText>
                    <Typography variant="body2" color="text.secondary">
                      <LogoutIcon
                        sx={{ transform: `rotate(180deg)`, color: "#6E6E6E" }}
                        fontSize="small"
                      />
                    </Typography>
                  </MenuItem>                       
                </MenuList>
              </Menu>
            </Box>
          </Toolbar>
        )}
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            color: "white",
          },
        }}
      >
        <IconButton>
          <Typography
            component={"div"}
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              width: drawerWidth,
              background: "#089BAE",
              alignItems: "center",
              borderRadius: 2,
              padding: 1,
              zIndex: 1
            }}
            onClick={handleDrawer}
          >
            {/* <Typography> */}
            <img src={largeLogoIcon} alt="logo" height={45} />
            {/* </Typography> */}
            {open && (
              <Typography
                component={"h2"}
                sx={{ fontSize: 20, fontWeight: 600, color: "white" }}
              >
                Botifier
              </Typography>
            )}
          </Typography>
        </IconButton>

        {!!user.email && (
          <NavigationList
            items={navigationList}
            //isDivider
            isLink
            isOpen={open}
          />
        )}
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Sidebar);