import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";

export const AnalyticsCard = ({ countValue, animationData, title }) => {
  const [cardTitle, setCadTitle] = useState("");
  const [count, setCount] = useState(0);
  const [defaultOptions, setDefaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  });
  useEffect(() => {
    if (!!title) {
      setCadTitle(title);
    }
    if (!!animationData) {
      setDefaultOptions({
        ...defaultOptions,
        animationData,
      });
    }
    if (!!countValue || countValue === 0) {
      setCount(countValue);
    }
  }, [countValue, animationData, title]);
  return (
    <>
      <Typography
        component={"div"}
        sx={{
          p: 2,
          background: "#FFFFFF",
          display: "flex",
          boxShadow: "inset 0px 3px 6px #C8C8C880",
          border: "0.5px solid #C8C8C8",
          borderRadius: "5px",
          width: "100%",         
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography component={"div"} sx={{ width: 150, height: 140 }}>
          <Lottie options={defaultOptions} />
        </Typography>
        <Typography
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#089BAE",
            borderRadius: "5px",
            p:2,
            width: 160,
            height: 140,
          }}
        >
          <Typography
            component={"div"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
              width: "100%",
              height: 140,
            }}
          >
            <h1>{count}</h1>
          </Typography>
          <Typography
            component={"p"}
            sx={{
              textAlign: "center",
              font: "normal normal 600 14px/17px 'Graphie, Regular'",
              letterSpacing: "0px",
              color: "#FFFFFF",
            }}
          >
            {cardTitle}
          </Typography>
        </Typography>
      </Typography>
    </>
  );
};
