import React, { useState, useEffect } from "react";
import { DialogModal } from "../Modal/DialogModal";
import {
  Typography,
  Button,
  CircularProgress,
  InputLabel,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { theme } from "../../utils/theme";
// import './ApiComponent.module.scss'; // Import the SCSS file
import styles from "./ApiComponent.module.scss";
import axios from "axios";
const { setApiDetails } = require("../../api");

export const ApiComponent = ({
  apiDetail,
  open,
  onClose,
  getApis,
  fetchAddonData,
}) => {
  const [apiName, setApiName] = useState("");
  const [apiEndpoint, setApiEndpoint] = useState("");
  const [jsonPayload, setJsonPayload] = useState("");
  const [httpMethod, setHttpMethod] = useState("GET");
  const [httpHeader, setHttpHeader] = useState("");
  const [response, setResponse] = useState("");
  const [responseKeys, setResponseKeys] = useState([]);
  const [_id, setId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (apiDetail) {
      setApiName(apiDetail?.apiName);
      setApiEndpoint(apiDetail?.apiEndpoint);
      setJsonPayload(apiDetail?.jsonPayload);
      setHttpMethod(apiDetail?.httpMethod);
      setHttpHeader(apiDetail?.httpHeader);
      setResponseKeys(apiDetail?.responseKeys);
      setId(apiDetail?._id);
    }
  }, [apiDetail]);

  const Submit = async () => {
    if (!apiName || !apiName.trim() || !apiEndpoint) {
      return toast.error("Please fill all the fields ")
    }
    if (responseKeys.length == 0) {
      return toast.error("Please add atleast one response key");
    }
    const body = {
      _id,
      apiName,
      apiEndpoint,
      jsonPayload,
      httpMethod,
      httpHeader,
      responseKeys,
    };
    try {
      const response = await setApiDetails(body);
      if (response) {
        if (apiDetail?._id) {
          toast.success("Api updated successfully ");
        } else {
          toast.success("Api added successfully ");
        }
      }
      fetchAddonData();
      onClose();
      getApis();
    } catch (err) {
      toast.error(err?.error);
      console.log(err);
    }
    console.log("response", response);
  };

  const resetStates = () => {
    setResponse("");
    setResponseKeys([]);
  };

  const handleNameChange = (e) => {
    setApiName(e.target.value);
  };

  const handleUrlChange = (e) => {
    setApiEndpoint(e.target.value);
    resetStates();
  };

  const handleBodyChange = (e) => {
    setJsonPayload(e.target.value);
    resetStates();
  };

  const handleMethodChange = (e) => {
    setHttpMethod(e.target.value);
    setJsonPayload("");
    resetStates();
  };

  const handleHeaderChange = (e) => {
    setHttpHeader(e.target.value);
    resetStates();
  };

  function flattenObject(obj, prefix = "") {
    let flattened = {};
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        const nestedObj = flattenObject(obj[key], prefix + key + ".");
        flattened = { ...flattened, ...nestedObj };
      } else {
        flattened[prefix + key] = obj[key];
      }
    }
    return flattened;
  }

  function isValidJSON(jsonString) {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  }

  const handleTestButtonClick = async () => {
    try {
      if (!apiEndpoint) {
        return toast.error("Please select api endpoint");
      }
      if (jsonPayload != "" && !isValidJSON(jsonPayload))
        return toast.error(
          " Please ensure the request body is properly formatted as valid JSON"
        );
      if (httpHeader != "" && !isValidJSON(httpHeader))
        return toast.error(
          "Please ensure the request header is properly formatted as valid JSON"
        );

      setLoading(true);
      const requestBody = jsonPayload ? JSON.parse(jsonPayload) : null;
      const requestHeader = httpHeader ? JSON.parse(httpHeader) : null;

      const config = {
        url: apiEndpoint,
        method: httpMethod,
        headers: {
          "Content-Type": "application/json",
          ...(requestHeader || {}), // Include requestHeader if not null, or an empty object if null
        },
      };

      if (requestBody) {
        config.data = requestBody; // Include requestBody if not null
      }

      const response = await axios(config);

      const flat_Res_Data = flattenObject(response?.data);
      setResponse(flat_Res_Data);
      setLoading(false);
      toast.success("Api data fetched successfully!");
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      let errorMessage = "An error occurred";
      if (
        !!error?.response?.data &&
        Object.keys(error.response.data).length !== 0
      ) {
        errorMessage = error?.response?.data;
      } else if (error?.message) {
        errorMessage = error?.message;
      }

      toast.error(errorMessage);
    }
  };

  const handleAttributeClick = (attributeName) => {
    if (responseKeys.includes(attributeName)) {
      setResponseKeys((prevSelectedAttributes) =>
        prevSelectedAttributes.filter((attr) => attr !== attributeName)
      );
    } else {
      setResponseKeys((prevSelectedAttributes) => [
        ...prevSelectedAttributes,
        attributeName,
      ]);
    }
  };

  const renderFields = () => {
    const renderedFields = [];

    for (let key in response) {
      renderedFields.push(
        <div key={key} className={styles.ApiRespponse}>
          {key}
          {/* <button onClick={() => handleAttributeClick(key)}>{responseKeys.includes(key) ? "-" : "+"}</button> */}
          <Button
            variant="contained"
            sx={{
              color: "#3E3E3E",
              textTransform: "none",
              minWidth: "24px",
              padding: "0px",
              background: "#ECECEC",
              mx: "6px",
              "&:hover": {
                background: "#b4b5b5",
              },
            }}
            onClick={() => handleAttributeClick(key)}
          >
            {responseKeys.includes(key) ? "-" : "+"}
          </Button>
          : {response[key]}
        </div>
      );
    }

    return renderedFields;
  };

  return (
    <DialogModal open={open}>
      <div className={styles.container}>
        <div className={styles.api_component}>
          <Typography
            component={"div"}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              mb: "15px",
              gap: 0.6,
            }}
          >
            <InputLabel
              sx={{
                textTransform: "capitalize",
                font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
                letterSpacing: "0px",
                color: "#3E3E3E",
              }}
            >
              Api Name:
            </InputLabel>
            {/* <input type="text" value={apiName} onChange={handleNameChange} /> */}
            <TextField
              sx={{
                "& input::placeholder": {
                  font: "normal normal normal 12px/16px 'Graphie, Regular'",
                  letterSpacing: "0px",
                  color: "#3E3E3E",
                  // mr:"20px"
                },
                "& fieldset": {
                  borderColor: "#C8C8C8", // Set the default border color
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#089BAE", // Border color on hover
                  },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#089BAE", // Border color on input focus
                  },
              }}
              inputProps={{
                style: {
                  height: 18,
                  width: "100%",
                  padding: 9,
                  background: "white",
                  borderRadius: "5px",
                  font: "normal normal normal 12px/16px 'Segoe UI'",
                  letterSpacing: "0px",
                  color: "#3E3E3E",
                  opacity: 1,
                },
                placeholder: "Enter here......",
              }}
              value={apiName}
              onChange={handleNameChange}
            />
          </Typography>
          <Typography
            component={"div"}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              mb: "15px",
              gap: 0.6,
            }}
          >
            <InputLabel
              sx={{
                textTransform: "capitalize",
                font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
                letterSpacing: "0px",
                color: "#3E3E3E",
              }}
            >
              URL:
            </InputLabel>
            {/* <input type="text" value={apiEndpoint} onChange={handleUrlChange} /> */}
            <TextField
              sx={{
                "& input::placeholder": {
                  font: "normal normal normal 12px/16px 'Graphie, Regular'",
                  letterSpacing: "0px",
                  color: "#3E3E3E",
                  // mr:"20px"
                },
                "& fieldset": {
                  borderColor: "#C8C8C8", // Set the default border color
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#089BAE", // Border color on hover
                  },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#089BAE", // Border color on input focus
                  },
              }}
              inputProps={{
                style: {
                  height: 18,
                  width: "100%",
                  padding: 9,
                  background: "white",
                  borderRadius: "5px",
                  font: "normal normal normal 12px/16px 'Segoe UI'",
                  letterSpacing: "0px",
                  color: "#3E3E3E",
                  opacity: 1,
                },
                placeholder: "Enter here......",
              }}
              value={apiEndpoint}
              onChange={handleUrlChange}
            />
          </Typography>
          <Typography
            component={"div"}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              mb: "15px",
              gap: 0.6,
            }}
          >
            <InputLabel
              sx={{
                textTransform: "capitalize",
                font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
                letterSpacing: "0px",
                color: "#3E3E3E",
              }}
            >
              Method:
            </InputLabel>
            <select
              value={httpMethod}
              onChange={handleMethodChange}
              className={styles.focus}
            >
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="PATCH">PATCH</option>
              <option value="DELETE">DELETE</option>
            </select>
          </Typography>

          {httpMethod != "GET" && (
            <Typography
              component={"div"}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                mb: "15px",
                gap: 0.6,
              }}
            >
              {/* <label>Body:</label> */}
              <InputLabel
                sx={{
                  textTransform: "capitalize",
                  font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
                  letterSpacing: "0px",
                  color: "#3E3E3E",
                }}
              >
                Body:
              </InputLabel>
              <textarea
                placeholder='{
               "key": "value",
               "key2": "value2",
               ...
           }'
                className={styles.myTextarea}
                type="text"
                value={jsonPayload}
                onChange={handleBodyChange}
              />
            </Typography>
          )}

          {/* <label>Header:</label> */}
          <Typography
            component={"div"}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              mb: "15px",
              gap: 0.6,
            }}
          >
            <InputLabel
              sx={{
                textTransform: "capitalize",
                font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
                letterSpacing: "0px",
                color: "#3E3E3E",
              }}
            >
              Header:
            </InputLabel>
            <textarea
              placeholder="{
            Content-Type: application/json
           Authorization: Bearer <token>
          }"
              type="text"
              style={{ whiteSpace: "pre" }}
              value={httpHeader}
              onChange={handleHeaderChange}
            />
          </Typography>

          {/* <button onClick={handleTestButtonClick} className={styles.conversationsButton}>Test</button> */}
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              width: "fit-content",
              pl: 4,
              pr: 4,
              borderRadius: 1,
              background: "#089BAE",
              "&:hover": {
                background: "#148695",
              },
            }}
            onClick={handleTestButtonClick}
          >
            Test
          </Button>
          <div
            style={{
              scroll: "auto",
              backgroundColor: "black",
              color: "white",
              padding: "6px",
              margin: "10px 0px",
              borderRadius: "10px",
              width: "100%",
              minHeight: "250px",
            }}
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "250px",
                }}
              >
                {" "}
                <CircularProgress />
              </div>
            ) : (
              renderFields()
            )}
          </div>

          <div className={styles.attributes}>
            {responseKeys.length > 0 && (
              <InputLabel
                sx={{
                  mt: "10px",
                  textTransform: "capitalize",
                  font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
                  letterSpacing: "0px",
                  color: "#3E3E3E",
                }}
              >
                Response Keys:
              </InputLabel>
            )}
            {responseKeys.map((attribute) => (
              <div key={attribute}>
                <Button
                  variant="contained"
                  sx={{
                    color: "#3E3E3E",
                    textTransform: "none",
                    minWidth: "24px",
                    padding: "0px",
                    background: "#ECECEC",
                    mx: "6px",
                    "&:hover": {
                      background: "#b4b5b5",
                    },
                  }}
                  onClick={() => handleAttributeClick(attribute)}
                >
                  {responseKeys.includes(attribute) ? "-" : "+"}
                </Button>
                {attribute}
              </div>
            ))}
          </div>
          {/* </div> */}
          {/* </div> */}
          <Typography
            component={"div"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              mt: "30px",
              width: "100%",
              gap: "200px",
              mb: "20px",
              px: "18px",
            }}
          >
            <Button
              variant="contained"
              component={"div"}
              sx={{
                textTransform: "capitalize",
                color: "#3E3E3E",
                background: "#ECECEC 0% 0% no-repeat padding-box",
                borderRadius: "5px",
                font: "normal normal 600 14px/19px 'Segoe UI'",
                letterSpacing: "0px",
                py: "8px",
                px: "55px",
                "&:hover": {
                  background: "#b4b5b5",
                },
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                font: "normal normal 600 14px/19px 'Segoe UI'",
                color: "#FFFFFF",
                background: "#089BAE",
                borderRadius: "5px",
                letterSpacing: "0px",
                px: "55px",
                py: "8px",
                "&:hover": {
                  background: "#148695",
                },
              }}
              onClick={() => Submit()}
            >
              Submit
            </Button>
          </Typography>
        </div>
      </div>
    </DialogModal>
  );
};
