import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootSaga from "./sagas/rootSaga";
import rootReducer from "./reducers";
import { createStore, applyMiddleware } from "redux";

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer(),
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);