import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet, Route, Routes } from "react-router-dom";
import styles from "./AppNavigation.module.scss";
import { ROUTES } from "../../utils/constant";
import { Sidebar } from "../../pages/Outlets/Sidebar/Sidebar";
import { Typography } from "@mui/material";

export default function AppNavigation({ children }) {
  return (
    <>
      <Typography component={'div'} sx={{ display: "flex", height: "100%", overflowY: "hidden" }}>
        <CssBaseline />
        {children}
      </Typography>
    </>
  );
}

