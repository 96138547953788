import React from "react";
import { AddonDetailsForm } from "../../AddonDetailsForm/AddonDetailsForm";
import AddonDetailsActivatedBots from "../../AddonDetailsActivatedBots/AddonDetailsActivatedBots";
import AddonDetailsActivationPanel from "../../AddonDetailsActivationPanel/AddonDetailsActivationPanel";
import { BasicSelect } from "../../Select/Select";
import { Typography } from "@mui/material";

const ZappierAddonDetails = ({
  deactivateAddon,
  isConfigure,
  inputList,
  smdList,
  selectedSmd,
  commonOnSelectSmd,
  inputs,
  setInputs,
  isBotSelect,
  setIsBotSelect,
  openDetailForm,
  setSelectedSmd,
  addonData,
  handleDeactivateAllConfirmation,
  activatedSmd,
  setSmdList,
  commonHandleOpenDetailForm,
  commonOnSubmit,
  setSuccessData,
  isValid,
  setIsValid,
  commonValidate,
  errorMessage,
  commonDeactivateAllAddon,
  isDeactivateAllConfirmation,
  commonHandleEdit
}) => {

  const onSubmit = async () => {
      const response = await commonOnSubmit();
      setSuccessData(response);
      commonHandleOpenDetailForm();
  };

  const validate = () => {
    if (commonValidate()) {
      onSubmit();
    }
  };

  return (
    <div>
      <AddonDetailsActivationPanel
        addonData={addonData}
        handleDeactivateAllConfirmation={handleDeactivateAllConfirmation}
        handleOpenDetailForm={commonHandleOpenDetailForm}
        isConfigure={isConfigure}
        deactivateAllAddon={commonDeactivateAllAddon}
        isDeactivateAllConfirmation={isDeactivateAllConfirmation}
      />
      <AddonDetailsActivatedBots
        activatedSmd={activatedSmd}
        setSelectedSmd={setSelectedSmd}
        addonData={addonData}
        setInputs={setInputs}
        setIsBotSelect={setIsBotSelect}
        handleOpenDetailForm={commonHandleOpenDetailForm}
        setSmdList={setSmdList}
        deactivateAddon={deactivateAddon}
        handleEdit={commonHandleEdit}
      />
      {openDetailForm && !addonData?.editOnFrontend && (
        <AddonDetailsForm
          open={openDetailForm}
          smdList={smdList}
          isBotSelect={isBotSelect}
          selectedSmd={selectedSmd}
          onClose={commonHandleOpenDetailForm}
          inputList={inputList}
          onSelectSmd={commonOnSelectSmd}
          inputs={inputs}
          setInputs={setInputs}
          onSubmit={onSubmit}
          isValid={isValid}
          setIsValid={setIsValid}
          validate={validate}
          errorMessage={errorMessage}
        >
          <Typography component={"div"} sx={{ width: "100%", mb: "15px" }}>
              <BasicSelect
                width={"100%"}
                size="small"
                label={"Bots"}
                title="Bots"
                items={smdList}
                selected={selectedSmd}
                onSelect={commonOnSelectSmd}
                isDisabled={isBotSelect}
              />
            </Typography>
        </AddonDetailsForm>
      )}
    </div>
  );
};

export default ZappierAddonDetails;
