import { Outlet } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import  Sidebar  from "../pages/Outlets/Sidebar/Sidebar";
const DrawerHeader = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    minHeight: 90,
  };
});

function AdminRoutes() {
  return (
    <>
    <Sidebar/>
    <Typography
      component="main"
      sx={{ flexGrow: 1, pl: 2, pr:2,pt:12, minHeight: "100vh", overflowY: "hidden" , overflowX:'hidden', background:'transparent'}}
    >
      {/* <DrawerHeader /> */}
      <Outlet />
    </Typography>
    </>
  );
}

export default AdminRoutes;
