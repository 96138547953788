import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from "react";
import { styled } from '@mui/material/styles';
import { SORT_TYPE } from '../../../utils/constant';


const StyledSelect = styled(Select)((props) => ({
  textAlign: "center",
  background: '#F8F8F8 0% 0% no-repeat padding-box',
  borderRadius: '5px',
  opacity: '1',
  width: "100%",
  padding: props.padding ? `${props.padding}px` : "0px",

  '&.Mui-focused': {
    fieldset: {
      '&.MuiOutlinedInput-notchedOutline': {
          borderColor: '#089BAE', // Set the border color when focused
      }
    },
  },
}));

const DropDown = ({sortBots}) => {
  const [value, setValue] = useState(SORT_TYPE.NEW_TO_OLD);

  const handleChange = (event) => {
    setValue(event.target.value);
    sortBots(event.target.value);
  };
  return (
      <div>
      <FormControl sx={{
        mr: "3px",
        minWidth: 120,
      }}>
        <StyledSelect
           sx={{
            '& fieldset': {
              bottom: '7px',
              top: '2px',
            },
            '&.MuiInputBase-root': {
              background:"transparent"
            },
            '& .MuiMenuItem-root': {
              font: "normal normal normal 12px/16px 'Segoe UI'",
              letterSpacing: '0px',
              color: '#3E3E3E'
            },
          }}
          value={value}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value={ SORT_TYPE.NEW_TO_OLD}>New-Old</MenuItem>
          <MenuItem value={ SORT_TYPE.A_TO_Z}>A-Z</MenuItem>
          <MenuItem value={ SORT_TYPE.Z_To_A}>Z-A</MenuItem>
          <MenuItem value={ SORT_TYPE.OLD_TO_NEW}>Old-New</MenuItem>
        </StyledSelect>
        </FormControl>
    </div>
  )
}

export default DropDown;

