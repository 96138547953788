import React, { useContext, useEffect, useState } from "react";
import { DialogModal } from "../../components/Modal/DialogModal";

import { deleteBot } from "../../routines";
import { toast } from "react-toastify";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Slide,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { botClosingApi, initiateInstance } from "../../api";
import { NO_TRANSITION_EVENT_NAME } from "../../utils/jsonUtils";
import { FlowContext } from "../../store/flow-context";
import bannerImage from "./../../pages/Images/banner_image.png";

import styles from "./AdminDashboard.module.scss";


import DashboardBanner from "../../components/Banner/DashboardBanner";
import { connect, useDispatch } from "react-redux";
import { SORT_TYPE } from "../../utils/constant";
import { socketEvents, TestFlow } from "../../components/Flow/TestFlow";
import DropDown from '../../components/Common/DropDown/DropDown.js'
import { Init } from "../../components/Init/Init";
import { BotCard } from "../../components/Common/BotCard/BotCard";
import BotTemplates from "../../components/BotTamplates/BotTamplates";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" {...props} />;
});

const AdminDashboard = ({ bots, deleteBot }) => {
  const dispatch = useDispatch();
  const flowContext = useContext(FlowContext);
  const [isTestClicked, setIsTestClicked] = useState(false);
  const [botFirstMessage, setBotFirstMessage] = useState(null);
  const [botName, setBotName] = useState("");
  const [avatar, setAvatar] = useState("");
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [recentlyUsedBots, setRecentlyUsedBots] = useState([]);
  const [sortedBot, setSortedBot] = useState([]);
  const [isBotsLoading, setIsBotsLoading] = useState(true);

  const closeTemplateDialog = () => {
    setOpenTemplateDialog(false);
  }

  useEffect(() => {
    if (!!bots) {
    const latest3Smd = bots
      .filter((obj) => obj.updatedAt) // Remove objects without updatedAt key
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) // Sort in descending order
      .slice(0, 3); // Retrieve the latest 3 objects
    setRecentlyUsedBots(latest3Smd);

      const latestBots = [...bots];
      latestBots.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      setSortedBot(latestBots);
      setIsBotsLoading(false)
    }
  }, [bots]);

  const handleCreate = () => {
      localStorage.removeItem("flowData");
      flowContext.clearFlowData();
      setOpenTemplateDialog(true);
  };

  const handleCloseTestFlow = async (id) => {
    setBotFirstMessage(null);
    setBotName("");
    setIsTestClicked(false);
    await botClosingApi(id);
  };

  const handleTest = async ({ smd, botName, avatar }) => {
    async function initiate() {
      const res = await initiateInstance(smd);
      const events = res.events.find((evt) => evt === NO_TRANSITION_EVENT_NAME);
      setBotFirstMessage({
        message: res.message,
        events: !!events ? [] : res.events,
        type: socketEvents.BOT_REPLY,
        botId: res.bot_id,
      });
      setBotName(botName);
      setAvatar(avatar);
      setIsTestClicked(true);
    }
    initiate();
  };

  const sortBots = (type) => {
    const sortedBotName = [...sortedBot];

    switch (type) {
      case SORT_TYPE.A_TO_Z:
        sortedBotName.sort((a, b) => a.smdName.localeCompare(b.smdName));
        break;
      case SORT_TYPE.Z_To_A:
        sortedBotName.sort((a, b) => b.smdName.localeCompare(a.smdName));
        break;
      case SORT_TYPE.NEW_TO_OLD:
        sortedBotName.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        break;
      case SORT_TYPE.OLD_TO_NEW:
        sortedBotName.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
        break;
    }

    setSortedBot(sortedBotName);
  }

  return (
    <>
      <Typography component={"div"}>
        {process.env.REACT_APP_CAN_CREATE_BOT === "true" && <DashboardBanner image={bannerImage} imageWidth={40} imageHegiht={160}>
          <Typography
            sx={{
              height: 160,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Typography
              component={"div"}
              sx={{
                font: "normal normal normal 20px/24px 'Graphie, Regular'",
                letterSpacing: "0px",
                color: "#3E3E3E",
              }}
            >
              Create new Bot
            </Typography>
            <Typography
              varient="p"
              component={"div"}
              sx={{
                font: "normal normal normal 14px/19px 'Segoe UI'",
                letterSpacing: "0px",
                color: "#3E3E3E",
              }}
            >
              Botifier's AI-powered bot builder to easily create bots and
              conversational workflows.
            </Typography>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                width: "fit-content",
                pl: 4,
                pr: 4,
                borderRadius: 1,
                background: "#089BAE",
                "&:hover": {
                  background: "#148695",
                },
              }}
              onClick={handleCreate}
            >
              Add new bot
            </Button>
          </Typography>
        </DashboardBanner>}
      </Typography>
      {isBotsLoading ? (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      ) : !!bots.length && (
        <>
          <div className={styles.Container}>
            <Typography
              variant="p"
              component={"div"}
              sx={{
                mb: 2,
                font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
                letterSpacing: "0px",
                color: "#3E3E3E",
                ml: "12px",
              }}
            >
              Recently used bots
            </Typography>
            <div className={styles.content}>
              <Grid
                container
                lg={12}
                rowSpacing={3}
                columnSpacing={{ xs: 2.5, sm: 2.5, md: 2.5, lg: 2.5 }}
                jusitifyContent={"stretch"}
                sx={{ alignContent: "center", height: "" }}
              >
                {recentlyUsedBots?.map((bot, index) => (
                  <Grid item sm={12} md={6} lg={4}>
                    <BotCard
                      data={{ ...bot, editDisabled: process.env.REACT_APP_CAN_EDIT_BOT === '0' }}
                      index={index}
                      onTest={handleTest}
                      disableHoverEffect
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
          <div className={styles.dropDown}>
            <div className={styles.addedBots}>Added bots</div>
            <div className={styles.dropDownContent}>
              <DropDown sortBots={sortBots} />
            </div>
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.content}>
              <Grid
                container
                lg={12}
                rowSpacing={3}
                columnSpacing={{ xs: 2.5, sm: 2.5, md: 2.5, lg: 2.5 }}
                sx={{ alignContent: "center", height: "" }}
              >
                {sortedBot.map((bot, index) => (
                  <Grid item sm={12} md={6} lg={4}>
                    <BotCard 
                      data={{ ...bot, editDisabled: process.env.REACT_APP_CAN_EDIT_BOT === '0' }}
                      index={index} onTest={handleTest}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </>
      )}
      {
        <Dialog
          classes={{
            root: styles.testContainer,
          }}
          PaperProps={{
            style: {
              background: "transparent",
              margin: "0px",
              border: "0.5px solid #C8C8C8",
              borderRadius: "15px 15px 10px 10px",
            },
          }}
          BackdropProps={{ style: { background: "transparent" } }}
          keepMounted
          open={isTestClicked}
          TransitionComponent={Transition}
          sx={{
            height: 800,
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent style={{ overflow: "hidden", padding: "0" }}>
            {isTestClicked && (
              <TestFlow
                showTestFlow={setIsTestClicked}
                botFirstMessage={botFirstMessage}
                runBotTitle={botName}
                avatar={avatar}
                handleCloseTestFlow={handleCloseTestFlow}
                isTest
              />
            )}
          </DialogContent>
        </Dialog>
      }
      <BotTemplates open={openTemplateDialog} onClose={closeTemplateDialog} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bots: state.bots,
  };
};
export default connect(mapStateToProps)(AdminDashboard);
