import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { initiateInstanceBySmd } from "../../api";
import { NO_TRANSITION_EVENT_NAME } from "../../utils/jsonUtils";
import { RunBot, socketEvents } from "./TestFlow";
import { botClosingApi } from "../../api";


import styles from "./Bot.module.scss";

export const Bot = () => {
  const [searchParams] = useSearchParams();
  const smdId = searchParams.get("smdId");
  const plugin = searchParams.get("plugin");
  const [botFirstMessage, setBotFirstMessage] = useState(null);
  const [botActive, setBotActive] = useState(true);

  async function initiateBot(smdId) {
    try {
      const res = await initiateInstanceBySmd({ smdId });
      const events = res.events.find((evt) => evt === NO_TRANSITION_EVENT_NAME);
      setBotFirstMessage({
        message: res.message,
        events: !!events ? [] : res.events,
        type: socketEvents.BOT_REPLY,
        botId: res.bot_id,
        smdId: smdId,
        smdName: res.smdName,
        avatar:res.avatar
      });
    } catch (err) {
      console.log(err);
      setBotActive(false);
    }
  }

  const handleCloseChat = async() => {
    await botClosingApi(botFirstMessage.botId);
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleCloseChat)
  }, [botFirstMessage])

  useEffect(() => {
    initiateBot(smdId);
  }, [smdId]);

  return (
    <div className={styles.botContainer}>
      {botActive ? !!botFirstMessage && (
        <RunBot
          botFirstMessage={botFirstMessage}
          showTestFlow={() => { }}
          runBotTitle={botFirstMessage.smdName}
          avatar={botFirstMessage.avatar}
          plugin={plugin === "true"}
        />
      ) : <h2>This bot is not active !! </h2>}
    </div>
  );
};
