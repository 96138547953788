import React, { useEffect, useState } from "react";
import { ROUTES, characterLength } from "../../utils/constant";
import axios from "../../api/axios";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import styles from "./ResetPassword.module.scss";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { useLocation } from "react-router-dom";
import HeaderIcon from "../Images/headerIcon.png";
import ChatIcon from "../Images/chatIcon.png";

const useStyles = makeStyles((theme) => ({
  // "@global": {
  //     body: {
  //         backgroundColor: theme.palette.common.white
  //     }
  // },
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    textTransform: "none",
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "red",
    color: "white",
  },
  signUpTypography: {
    color: theme.palette.secondary.main,
  },
}));
export const ResetPassword = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [verificationPage, setVerificationPage] = useState(false);
  const [token, setToken] = useState();

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setToken(params.get("token"));
    console.log(params.get("token"));
  }, [token]);

  async function reset_password(payload) {
    axios
      .post(`/resetpassword/${token}`, payload)
      .then((res) => {
        toast.success(res.data);
        navigate(ROUTES.LOG_IN);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      password1.trim().length < characterLength.passMinLength ||
      password1.trim().length > characterLength.passMaxLength
    ) {
      return toast.error("Password must consist of 8 to 15 characters");
    }
    if (password1 != password2) {
      return toast.error("Password is not matching");
    } else {
      await reset_password({ password: password1 });
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div className={styles.signUpFormContainer}>
        <Container maxWidth="xs" disableGutters>
          <CssBaseline />
          <div className={styles.container}>
            <div className={styles.upperSection}>
              <div className={styles.botifier}>
                <div className={styles.botifierDiv}>
                  {" "}
                  <img src={HeaderIcon} className={styles.headerIcon}></img>
                  Botifier
                </div>
              </div>
              <div className={styles.welcomeHeader}>Forget Password</div>
              <div>
                <img src={ChatIcon}></img>
              </div>
            </div>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Grid item xs={12}>
                <FormControl
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    marginBottom: "8px",
                  }}
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{
                      "&.Mui-focused": {
                        color: "#089BAE",
                      },
                    }}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword1 ? "text" : "password"}
                    className={styles.userNameInput}
                    error={
                      password1 &&
                      (password1.trim().length < characterLength.passMinLength ||
                       password1.trim().length > characterLength.passMaxLength)
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword1(!showPassword1)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    value={password1}
                    onChange={(e) => setPassword1(e.target.value)}
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#089BAE",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: "100%", backgroundColor: "white" }}>
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{
                      "&.Mui-focused": {
                        color: "#089BAE",
                      },
                    }}
                  >
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword2 ? "text" : "password"}
                    className={styles.userNameInput}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword2(!showPassword2)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    error={
                      password2 &&
                      (password1 !== password2 ||
                        password2.trim().length < characterLength.passMinLength ||
                        password2.trim().length > characterLength.passMaxLength)
                    }
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#089BAE",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
                onClick={handleSubmit}
                style={{ backgroundColor: "#089BAE", color: "#ffff" }}
              >
                Submit
              </Button>
              <Grid container justify="center">
                <Grid item>
                  <p
                    className={styles.siginNavigation}
                    onClick={() => {
                      navigate(ROUTES.LOG_IN);
                    }}
                  >
                    Sign in
                  </p>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </>
  );
};
