import { useContext, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import AddNodeInfo from "./AddNodeInfo";
import styles from "./CustomNode.module.scss";
import { FlowContext } from "../../store/flow-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { BootstrapTooltip } from "../UI/Tooltip";
import { guards } from "../../utils/constant";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function CustomNode(customNodeData) {
  const { addons, activatedAddon } = useSelector((state) => state.addonsList);
  const { data } = customNodeData;
  const [isConnectable, setisConnectable] = useState(data.createNewState ?? true);
  const [editing, setEditing] = useState(false);
  const [stateName, setStateName] = useState(data.stateName ?? "");
  const [nodeInfo, setNodeInfo] = useState({
    type: data.type ?? "Text",
    min: "",
    max: "",
    message: data.message ?? {
      'he-male': '',
      'he-female': '',
      en: "",
    },
    defaultMessage: data.defaultMessage,
    isInitialNode: data.isInitialNode,
    isGuardEditable: data.isGuardEditable ?? true,
    fallbacks: data.fallbacks ?? undefined,
    nodeAccesses: data.nodeAccesses ?? undefined
  });

  const flowContext = useContext(FlowContext);

  const editingCancelHandler = () => {
    setEditing(false);
    flowContext.setIsKeyboardDisabled(false)
  };

  const editNodeHandler = () => {
    if (data.hasOwnProperty('isMessageEditable') && data.isMessageEditable === false) {
      return toast.warning("This node is not allowed to change!");
    }
    setEditing(true);
    flowContext.setIsKeyboardDisabled(true);
  };

  useEffect(() => {
    if (data.apiDetails) {
      nodeInfo.apiDetails = data.apiDetails;
    }
    const nextNode = flowContext.flowData.edges.find(
      (elelment) => elelment.source === data.id
    );
    if (
      ((nodeInfo.type !== guards.isText || nodeInfo.defaultMessage) &&
        !!nextNode) || (data.hasOwnProperty('createNewState') && !data.createNewState)
    ) {
      setisConnectable(false);
    } else {
      setisConnectable(true);
    }
  }, [nodeInfo, flowContext]);

  const stateNameChangeHandler = (event) => {
    const value = event.target.value;
    const hasOnlySpace = !/\S/.test(value);
    if (hasOnlySpace && value || (data.hasOwnProperty('isStateNameEditable') && !data.isStateNameEditable)) return;
    setStateName(value);
    flowContext.addOrUpdateNode({
      ...customNodeData,
      data: {
        id: data.id,
        stateName: value !== "" ? event.target.value : undefined,
        message: nodeInfo.message,
        isInitialNode: nodeInfo.isInitialNode,
        defaultMessage: nodeInfo.defaultMessage,
        type: nodeInfo.type,
        fallbacks: nodeInfo.fallbacks,
        nodeAccesses: nodeInfo.fallbacks
      },
    });
  };

  const getPreviousNode = (selectedNodeId, elements) => {
    const edge = elements.find((element) => element.target === selectedNodeId);

    if (edge) {
      return flowContext.flowData.nodes.find(
        (element) => element.id === edge.source
      );
    }

    return null;
  };

  const handleNodeDelete = () => {
    if (data.hasOwnProperty('deleteState') && data.deleteState === false) {
      return toast.warning("This node is not allowed to delete!");
    }
    if (customNodeData.data.isInitialNode) {
      return toast.error("Can't delete the first node");
    }
    const previousNode = getPreviousNode(
      customNodeData.id,
      flowContext.flowData.edges
    );


    // if (!!previousNode && previousNode?.data?.defaultMessage) {
    //   return toast.error(`Please remove the external API from ${previousNode.data.stateName}`);
    // }

    flowContext.deleteNodeById(customNodeData);
  };
  const saveNodeInfoHandler = (info) => {
    setNodeInfo(info);
    flowContext.addOrUpdateNode({
      ...customNodeData,
      data: {
        id: data.id,
        stateName,
        message: info.message,
        type: info.type,
        isInitialNode: info.isInitialNode,
        defaultMessage: info.defaultMessage,
        fallbacks: info.fallbacks,
        nodeAccesses: info.nodeAccesses
      },
    });
    setEditing(false);
    flowContext.setIsKeyboardDisabled(false)
  };

  return (
    <div className={styles.customNode}>

      <AddNodeInfo
        open={editing}
        activatedAddon={activatedAddon}
        nodeInfo={nodeInfo}
        cancelNodeInfo={editingCancelHandler}
        saveNodeInfo={saveNodeInfoHandler}
        nodeId={data.id}
      />

      <Handle
        type="target"
        position={Position.Left}
        isConnectable={data.isEdgeConnectable ?? true}
      />
      <div
        className={`${styles.nodeContainer} ${!!nodeInfo.message.en && nodeInfo.message['he-male'].trim().length > 0 && nodeInfo.message['he-female'].trim().length > 0 && !!stateName
          ? styles.nodeContainerValid
          : styles.nodeContainerInvalid
          }`}
      >
        {/* <div className={styles.nodeHeader}> */}
        <input
          className={styles.stateName}
          placeholder="Add a Title"
          value={stateName}
          onChange={stateNameChangeHandler}
          maxLength={11}
        />
        {!(data.hasOwnProperty('isMessageEditable') && data?.isMessageEditable === false) && <BootstrapTooltip title="Add Details" arrow placement="top">
          <FontAwesomeIcon
            icon={faGear}
            className={styles.nodeOptionsIcon}
            onClick={editNodeHandler}
          />
        </BootstrapTooltip>}
        {!customNodeData.data.isInitialNode && !(data.hasOwnProperty('deleteState') && data?.deleteState === false) && <BootstrapTooltip title="Delete Node" arrow placement="top">
          {/* <FontAwesomeIcon
            icon={faTrash}
            className={styles.nodeOptionsIcon}
            onClick={handleNodeDelete}
          /> */}
          <DeleteOutlinedIcon
            sx={{
              color:
                `${!!nodeInfo.message && !!stateName ? "#089BAE" : "#9b0c0cc9"}`,
              fontSize: 20,
              cursor: "pointer",
            }}
            onClick={handleNodeDelete}
          />
        </BootstrapTooltip>}
      </div>
      <BootstrapTooltip
        title={isConnectable ? "New Node" : "Cannot Create New Node"}
        arrow
        placement="right"
      >
        <Handle
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
        />
      </BootstrapTooltip>
    </div>
  );
}

export default CustomNode;
