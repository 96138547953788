import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  MenuItem,
  Grid,
  FormControl,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const NodeMapper = ({ nodes, objectKeys, mappings, setMappings }) => {

  const handleAddMapping = () => {
    if (
      mappings.some((mapping) => {
        const availableNodes = nodes.filter(
          (node) => !mappings.map((m) => m.node).includes(node)
        );
        const availableObjectKeys = objectKeys.filter(
          (key) => !mappings.map((m) => m.objectKey).includes(key)
        );
        return availableNodes.length && availableObjectKeys.length;
      })
    ) {
      setMappings([...mappings, { node: '', objectKey: '' }]);
    }
  };

  const handleRemoveMapping = (index) => {
    const updatedMappings = [...mappings];
    updatedMappings.splice(index, 1);
    setMappings(updatedMappings);
  };

  const handleNodeChange = (index, selectedNode) => {
    const updatedMappings = [...mappings];
    updatedMappings[index].node = selectedNode;
    setMappings(updatedMappings);
  };

  const handleObjectKeyChange = (index, selectedObjectKey) => {
    const updatedMappings = [...mappings];
    updatedMappings[index].objectKey = selectedObjectKey;
    setMappings(updatedMappings);
  };

  const isMappingValid = (mapping) => {
    const availableNodes = nodes.filter(
      (node) => !mappings.map((m) => m.node).includes(node)
    );
    const availableObjectKeys = objectKeys.filter(
      (key) => !mappings.map((m) => m.objectKey).includes(key)
    );
    
    return mapping.node && mapping.objectKey && availableNodes.length > 0 && availableObjectKeys.length > 0;
  };


  return (
    <div>
      {mappings.map((mapping, index) => (
        <Grid container key={index} alignItems="center" spacing={2}>
          <Grid item>
            <FormControl size="small" style={{ width: '200px', marginBottom: 8 }}>
              <TextField
                size="small"
                select
                value={mapping.node}
                label='Node'
                onChange={(e) => handleNodeChange(index, e.target.value)}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C8C8C8", 
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#089BAE", 
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#089BAE", 
                  },
                }}
                InputLabelProps={{ 
                  style: {
                    color: "#3E3E3E"
                  },
                }}
              >
                {nodes
                  .filter(
                    (node) =>
                      !mappings
                        .filter((m, i) => i !== index)
                        .map((m) => m.node)
                        .includes(node)
                  )
                  .map((node) => (
                    <MenuItem key={node} value={node}>
                      {node}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl size="small" style={{ width: '200px', marginBottom: 8 }}>
              <TextField
                select
                size="small"
                value={mapping.objectKey}
                label='Object key'
                onChange={(e) => handleObjectKeyChange(index, e.target.value)}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C8C8C8", 
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#089BAE", 
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#089BAE", 
                  },
                }}
                InputLabelProps={{ 
                  style: {
                    color: "#3E3E3E"
                  },
                }}
              >
                {objectKeys
                  .filter(
                    (key) =>
                      !mappings
                        .filter((m, i) => i !== index)
                        .map((m) => m.objectKey)
                        .includes(key)
                  )
                  .map((key) => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
          </Grid>
          {index !== 0 && (
            <Grid item>
              <IconButton
                onClick={() => handleRemoveMapping(index)}
                size="large"
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
      <Box>
        <IconButton
          onClick={handleAddMapping}
          size="large"
          disabled={!isMappingValid(mappings[mappings.length - 1])}
        >
          <AddIcon />
        </IconButton>
      </Box>
    </div>
  );
};

export default NodeMapper;
