import React, { useEffect, useState } from "react";
import { Typography, TextField } from "@mui/material";
import { getChatById } from "../../api";
import { ChatWindow } from "../ChatWindow/ChatWindow";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import styles from "./ChatSideWindow.module.scss";
import { useParams } from "react-router-dom";
import moment from "moment";
import Spinner from '../Spinner'

export const ChatsideWindow = ({
  conversation,
  fetchingConversations,
  fetchSmdChats,
  rowCount,
  searchText,
  setSearchText,
}) => {
  const [chatData, setChatData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const {uid} = useParams()

  const handleButtonClick = async (botId, index=0) => {
    const res = await getChatById(botId);
    setActiveIndex(index);
    setChatData(res);
  };

  useEffect(() => {
    if (conversation?.length > 0) {
      handleButtonClick(conversation?.[0]?.botId);
    }
  }, [conversation]);

  const handlePageChange = (currentPage) => {
    fetchSmdChats(false, currentPage - 1);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  if (fetchingConversations) {
    return (
      <div className="mt-10">
        <Spinner text="Loading chats..." />
      </div>
    );
  }

  return (
    <>
      <Typography component={"div"} sx={{ width: "100%" }}>
        <Typography
          component={"div"}
          sx={{
            width: "100%",
            p: "5px",
            mb: "2px",
            borderRadius: "5px",
            mt: "12px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#e6e6e6",
          }}
        >
          <TextField
            sx={{
              width: "25%",
              // ml: '7px',
              background: "#ECECEC 0% 0% no-repeat padding-box",
              border: "0.5px solid #707070",
              borderRadius: "5px",
              "& input": {
                padding: "10px 0px 10px 10px",
                font: "normal normal 600 16px/19px 'Segoe UI'",
                letterSpacing: "0px",
                color: "#3E3E3E",
              },
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
              },
            }}
            variant="outlined"
            placeholder="Search..."
            onChange={handleSearchChange}
            value={searchText}
            disabled={ !!uid ? true : false}
          />
          {conversation?.length > 0 && (
            <Typography
              sx={{ display: "flex", alignItems: "center", margin: "auto", p:1 }}
            >
              <img
                src="/user-img.png"
                alt=""
                width={30}
                height={30}
                style={{ borderRadius: "50%", marginLeft: "15px" }}
              />
              <Typography
                component={"div"}
                sx={{
                  ml: "5px",
                  font: "normal normal 600 14px/19px 'Segoe UI'",
                  letterSpacing: "0px",
                  color: "#3E3E3E",
                  opacity: 1,
                }}
              >
                {conversation[activeIndex]?.uid}
              </Typography>
            </Typography>
          )}
        </Typography>
      </Typography>
      {conversation?.length > 0 ? (
        <Typography component={"div"} sx={{ display: "flex" }}>
          <Typography
            component={"div"}
            sx={{ boxShadow: "3px 0px 6px #ECECEC", width: "35%" }}
          >
            <Typography
              component={"div"}
              className={styles.container}
              sx={{
                boxShadow: "3px 0px 6px #ECECEC",
                overflow: "auto",
                height: "65vh",
                marginBottom: "1rem",
              }}
            >
              <Typography>
                {conversation?.map((user, index) => {
                  return user?.uid ? (
                    <Typography
                      component="div"
                      sx={{
                        wordBreak: "break-word",
                        mb: "10px", // Increased marginBottom for more spacing between users
                        padding: "10px 10px 11px 20px",
                        font: "normal normal 500 14px/19px 'Segoe UI'",
                        letterSpacing: "0px",
                        color: "#3E3E3E",
                        background:
                          activeIndex === index ? "#e6e6e6" : "#FCFCFC",
                        opacity: 1,
                        "&:hover": {
                          background: "#e6e6e6",
                          boxShadow: "0px 5px 6px #3E3E3E26",
                          color: "#3E3E3E",
                          opacity: 1,
                        },
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: "#777",
                        borderRadius: "4px", // Rounded corners
                        height: "60px", // Set the height of each user element
                        transition: "background-color 0.3s", // Smooth transition on hover
                        cursor: "pointer", // Show pointer cursor on hover
                      }}
                      onClick={() => {
                        handleButtonClick(user?.botId, index);
                      }}
                    >
                      <span>{user?.uid}</span>
                      <span>
                        {moment(user?.updatedAt).format("DD-MM-YYYY")}
                      </span>
                    </Typography>
                  ) : null;
                })}
              </Typography>
            </Typography>
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Pagination
                count={Math.ceil(rowCount / 10)}
                onChange={(event, pageNumber) => handlePageChange(pageNumber)}
                variant="outlined"
              />
            </Stack>
          </Typography>

          <Typography
            component={"div"}
            sx={{ width: "100%", marginLeft: "1rem", height: "65vh" }}
          >
            <ChatWindow chatData={chatData} />
          </Typography>
        </Typography>
      ) : (
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height:'100%',
            mt: 10,
          }}
        >
         {!!uid ? "This user has not interacted with this bot yet":" No users have interacted with this chatbot yet"}
        </Typography>
      )}
    </>
  );
};
