import { handleActions } from "redux-actions";
import { getUser } from "../routines";

const defaultState = {};

export const userDetailsReducer = handleActions(
  {
    [getUser.SUCCESS]: (state, { payload }) => {
      return { ...payload };
    },
  },
  defaultState
);
