import { React, useState } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import styles from "./Dialog.module.scss";
import { theme } from "./../../utils/theme.js";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxWidth: "100%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
};

export const DialogModal = (props) => {
  const {
    title,
    isHeader,
    isIcon,
    children,
    open,
    onClose,
    hideBackdrop,
    visibleBackdrop,
  } = props;

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        component={"div"}
        hideBackdrop={hideBackdrop}
        BackdropProps={{
          style: {
            background: visibleBackdrop ? "#0000002e" : "none",
            backdropFilter: visibleBackdrop ? "blur(10px)" : "none",
          },
        }}
      >
        <Typography sx={style} component={"div"}>
          {isHeader && (
            <Typography
              className={styles.dialog_header}
              sx={{ backgroundColor: theme.palette.primary.main }}
            >
              <Typography
                variant="h5"
                gutterBottom
                component={"div"}
                sx={{ m: 0, color: "white" }}
              >
                {title}
              </Typography>

              {isIcon && (
                <CloseIcon
                  sx={{
                    color: "white",
                    ":hover": {
                      color: theme.palette.secondary.dark,
                    },
                  }}
                  onClick={onClose}
                />
              )}
            </Typography>
          )}
          {children}
        </Typography>
      </Modal>
    </div>
  );
};
