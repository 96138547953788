import { takeEvery,takeLatest, put, call } from 'redux-saga/effects';
import { getAddons, getActivatedApis, createAPIKey, deleteAPIKeys, getAPIKeys  } from '../routines';
import { toast } from "react-toastify";
import {Apis} from './../api'// Replace with your actual API function

function* getAddonsSaga() {
  try {
    yield put(getAddons.request());
    const response = yield call(Apis.getBotAddonsList); // Call  API function
    yield put(getAddons.success({...response})); // set response to reducer
  } catch (error) {
    yield put(getAddons.failure(error.message));
  } finally {
    yield put(getAddons.fulfill());
  }
}

function* getActivatedApisSaga() {
  try {
    yield put(getActivatedApis.request());
    const response = yield call(Apis.getActivatedApis);
    yield put(getActivatedApis.success(response)); 
  } catch (error) {
    yield put(getAddons.failure(error.message));
  } finally {
    yield put(getAddons.fulfill());
  }
}

function* createApiKeySaga({ payload }) {
  try {
    yield put(createAPIKey.request());
    const response = yield call(Apis.setApiKey, { ...payload });
    yield put(createAPIKey.success());
    toast.success('Api key created successfully');
    yield put(getAPIKeys.trigger());
   
  } catch (error) {
    toast.error(error.message);
    yield put(createAPIKey.failure(error.message));
  } finally {
    yield put(createAPIKey.fulfill());
  }
}

function* getApiKeysSaga() {
  try {
    yield put(getAPIKeys.request());
    const response = yield call(Apis.getApiKeys);
    const apiKeys = [];
    for (let key in response.All_API_Keys) {
      apiKeys.push(response.All_API_Keys[key]);
    }
    yield put(getAPIKeys.success(apiKeys));
  } catch (error) {
    toast.error(error.message);
    yield put(getAPIKeys.failure(error.message));
  } finally {
    yield put(getAPIKeys.fulfill());
  }
}

function* deleteApiKeySaga({payload}) {
  try {
    yield put( deleteAPIKeys.request());
    const response = yield call(Apis.deleteApiKey,{apiKey:payload});
    yield put(deleteAPIKeys.success());
    yield put(getAPIKeys.trigger());
    toast.success(response.message);
  } catch (error) {
    console.log("response",error)
    toast.error(error.message);
    yield put(deleteAPIKeys.failure(error.message));
  } finally {
    yield put(deleteAPIKeys.fulfill());
  }
}

export function* watchAddonsSaga() {
  yield takeLatest(getAddons.TRIGGER, getAddonsSaga);
  yield takeLatest(getActivatedApis.TRIGGER, getActivatedApisSaga);
  yield takeEvery(createAPIKey.TRIGGER, createApiKeySaga);
  yield takeLatest(getAPIKeys.TRIGGER, getApiKeysSaga);
  yield takeEvery(deleteAPIKeys.TRIGGER, deleteApiKeySaga);
}