import { handleActions } from "redux-actions";
import { getBots } from "../routines";

const defaultState = null;

export const botsReducer = handleActions(
  {
    [getBots.SUCCESS]: (state, { payload }) => {
      return [...payload];
    },
  },
  defaultState
);