import { toast } from "react-toastify";
import { takeEvery, takeLatest, put, call } from "redux-saga/effects";
import { createAPIKey, deleteAPIKeys, getAPIKeys, updateUserDetails, loginUsers, getUser, updatePassword } from "../routines";
import { Apis } from "./../api"; // Replace with your actual API function
import { ROUTES } from "../utils/constant";

function* updateUserDetailsSaga({ payload }) {
  try {
    yield put(updateUserDetails.request());
    const response = yield call(Apis.updateUserDetails, payload);
    yield put(updateUserDetails.success());
    yield put(getUser.trigger())
    toast.success(response.message);
  } catch (error) {
    toast.error(error.message);
    yield put(updateUserDetails.failure(error.message));
  } finally {
    yield put(updateUserDetails.fulfill());
  }
}

function* loginUsersSaga({ payload }) {
  try {
    yield put(loginUsers.request());
    const response = yield call(Apis.login, payload);
    yield put(loginUsers.success());
    localStorage.setItem("authToken", response.token);
    yield put(getUser.trigger())
    window.location.replace(ROUTES.DASHBOARD);
  } catch (error) {
    toast.error(error.message);
    yield put(loginUsers.failure(error.message));
  } finally {
    yield put(loginUsers.fulfill());
  }
}

function* userSaga() {
  try {
    yield put(getUser.request());
    const response = yield call(Apis.getUser);
    yield put(getUser.success(response));
  } catch (error) {
    toast.error(error.message);
    yield put(getUser.failure(error.message));
  } finally {
    yield put(getUser.fulfill());
  }
}

function* updatePasswordSaga({ payload }) {
  try {
    yield put(updatePassword.request());
    const response = yield call(Apis.updatePassword, payload);
    window.location.replace(`${ROUTES.RESET_PASSWORD}?token=${response}`);
    yield put(updatePassword.success());
  } catch (error) {
    toast.error(error.message);
    yield put(updatePassword.failure(error.message));
  } finally {
    yield put(updatePassword.fulfill());
  }
}

export function* watchAdminSaga() {
  yield takeLatest(updateUserDetails.TRIGGER, updateUserDetailsSaga);
  yield takeLatest(loginUsers.TRIGGER, loginUsersSaga);
  yield takeLatest(getUser.TRIGGER, userSaga);
  yield takeLatest(updatePassword.TRIGGER, updatePasswordSaga);
}
