export const getActionForType = (type) => {
  switch (type) {
    case "Text":
    case "Number":
    case "Email":
    case "URL":
      return "storeText";
    case 'Attachment':
      return 'storeAttachment';
    default:
      return "";
  }
};

export const getGuardForType = (type) => {
  switch (type) {
    case "Text":
      return "isText";
    case "Number":
      return "isNum";
    case "Email":
      return "isEmail";
    case "URL":
      return "isURL";
    case "Attachment":
        return "isAttachment";
    default:
      return "";
  }
};
