import { getActionForType, getGuardForType } from "./actionUtils";
import { guards } from "./constant";

export const NO_TRANSITION_EVENT_NAME = "TRIGGER";
export const NO_TRANSITION_HEBREW_EVENT_NAME = "TRIGGER";
export function createJsonFromNodeData({ nodes, edges, initInfo }) {
  const jsonData = {
    id: initInfo.name,
    description: initInfo.description,
    avatar: initInfo.avatar,
    predictableActionArguments: true,
    initial: nodes[0].data.stateName,
    context: {
      data: {},
    },
    states: {},
    events: {},
    messages: {},
    positions: {},
    hetoen: {},
    entohe: {},
    access: {},
    fallback: {},
  };

  let emailStateExists = false;

  nodes.forEach((node) => {
    const { stateName, message, type, id, defaultMessage, } =
      node.data;
    jsonData.context.data[stateName] = "-";
    if (!emailStateExists && type === "Email") {
      emailStateExists = true;
    }
    jsonData.events[stateName] = [];
    jsonData.messages[stateName] = message;
    jsonData.positions[stateName] = node.position;
    jsonData.states[stateName] = {
      on: {},
    };
    if (node.data.hasOwnProperty('isInitialNode') && node.data.isInitialNode) {
      jsonData.initial = stateName;
      jsonData.states[stateName].isInitialNode = node.data.isInitialNode;
    }
    if (node.data.hasOwnProperty('nodeAccesses')) {
      jsonData.access[stateName] = node.data.nodeAccesses;
    }

    if (node.data.hasOwnProperty('fallbacks')) {
      for (let key in node.data.fallbacks) {
        jsonData.messages[key] = node.data.fallbacks[key]
        if (jsonData.fallback.hasOwnProperty(stateName)) {
          jsonData.fallback[stateName].push(key)
        } else {
          jsonData.fallback[stateName] = [key]
        }
      }
    }
    const nodeEdges = edges.filter((edge) => edge.source === id);
    if (nodeEdges.length > 0) {
      nodeEdges.forEach((nodeEdge, index) => {
        const targetState = nodes.find((nde) => nde.id === nodeEdge.target);
        const events = nodeEdge.data?.transition;
        const actions = nodeEdge.data.actions
        const eventName = !events?.english
          ? NO_TRANSITION_EVENT_NAME
          : events.english;
        jsonData.states[stateName].on[eventName] = {
          actions: !!actions ? actions : getActionForType(type),
          cond: getGuardForType(type),
          target: targetState.data.stateName,
        };
        if (!!defaultMessage) {
          jsonData.states[stateName].on[eventName] = {
            ...jsonData.states[stateName].on[eventName],
            errorMessage: defaultMessage,
          };
        }
        jsonData.events[stateName].push(eventName);
        if (eventName !== NO_TRANSITION_EVENT_NAME) {
          jsonData.entohe[eventName] = events.hebrew
          jsonData.hetoen[events.hebrew] = eventName
        } else {
          jsonData.entohe[eventName] = NO_TRANSITION_HEBREW_EVENT_NAME;
          jsonData.hetoen[NO_TRANSITION_HEBREW_EVENT_NAME] = eventName
        }
      });
    } else {
      jsonData.states[stateName].on = null;
      jsonData.events[stateName].push('menu')
    }
  });

  // add an email state if not existing
  // if (!emailStateExists) {
  //   const emailStateName = `Email`;
  //   jsonData.events[emailStateName] = [NO_TRANSITION_EVENT_NAME];
  //   jsonData.messages[emailStateName] = {
  //     en: "Please enter your email",
  //     'he-male': 'נא להזין את האימייל שלך',
  //     'he-faFemale': 'נא להזין את האימייל שלך'
  //   }
  //   jsonData.states[emailStateName] = {
  //     on: {
  //       [NO_TRANSITION_EVENT_NAME]: {
  //         actions: getActionForType("Email"),
  //         cond: getGuardForType("Email"),
  //         target: jsonData.initial,
  //       },
  //     },
  //   };
  //   jsonData.context.data[emailStateName] = "-";
  //   jsonData.initial = emailStateName;
  // }

  // logic for final states
  // Object.keys(jsonData.states).forEach((state) => {
  //   if (Object.keys(jsonData.states[state].on).length === 0) {
  //     console.log("last state", state)
  //     // const finalNodeData = nodes.find((node) => node.data.stateName === state);
  //     // jsonData.states[state].on.TRIGGER = {
  //     //   cond: getGuardForType(finalNodeData.data.type),
  //     //   type: "final",
  //     // };
  //     // jsonData.events[state] = ["TRIGGER"];
  //     // jsonData.entohe["TRIGGER"] = NO_TRANSITION_HEBREW_EVENT_NAME;
  //     // jsonData.hetoen[NO_TRANSITION_HEBREW_EVENT_NAME] = "TRIGGER"
  //   }
  // });
  const oldSmd = JSON.parse(localStorage.getItem('flowData'))?.smd
  if (!!oldSmd) {
    const { entohe = {}, hetoen = {} } = JSON.parse(oldSmd)
    for (let key in entohe) {
      if (!jsonData.entohe.hasOwnProperty(key)) {
        jsonData.entohe[key] = entohe[key]
      }
    }
    for (let key in hetoen) {
      if (!jsonData.hetoen.hasOwnProperty(key)) {
        jsonData.hetoen[key] = hetoen[key]
      }
    }
  }

  return JSON.stringify(jsonData);
}

export const createJsonSmdtoNodeEdges = (smd) => {
  try {
    const smdJson = typeof smd === "string" ? JSON.parse(smd) : smd;
    const { messages, positions, states, entohe = {}, access, fallback, } = smdJson;
    let nodes = [];
    let edges = [];
    let nodeId = {};

    for (let key in positions) {
      nodeId[key] = Math.floor(Math.random() * 100000);
    }
    for (let key in positions) {
      const node = {
        data: {
          id: `${nodeId[key]}`,
          message: messages[key],
          stateName: key,
          type: !states[key]?.on || Object.keys(states[key]?.on).length !== 1
            ? "Text"
            : guards[states[key]?.on[Object.keys(states[key]?.on)[0]].cond],
        },
        dragging: false,
        id: `${nodeId[key]}`,
        height: 40,
        width: 28,
        position: positions[key],
        positionAbsolute: positions[key],
        selected: false,
        type: "custom",
      };
      if (states[key].hasOwnProperty("isInitialNode")) {
        node.data.isInitialNode = states[key].isInitialNode
      }
      if (fallback.hasOwnProperty(key)) {
        node.data.fallbacks = {}
        fallback[key].forEach((item) => {
          node.data.fallbacks[item] = messages[item];
        })
      }
      if (!!access && access.hasOwnProperty(key)) {
        node.data.nodeAccesses = access[key];
        if (access[key].hasOwnProperty("isMessageEditable")) {
          node.data["isMessageEditable"] = access[key].isMessageEditable;
        }
        if (access[key].hasOwnProperty("createNewState")) {
          node.data["createNewState"] = access[key].createNewState;
        }
        if (access[key].hasOwnProperty("isStateNameEditable")) {
          node.data["isStateNameEditable"] = access[key].isStateNameEditable;
        }
        if (access[key].hasOwnProperty("deleteState")) {
          node.data["deleteState"] = access[key].deleteState;
        }
        if (access[key].hasOwnProperty("isEdgeConnectable")) {
          node.data["isEdgeConnectable"] = access[key].isEdgeConnectable;
        }
        if (access[key].hasOwnProperty("isGuardEditable")) {
          node.data["isGuardEditable"] = access[key].isGuardEditable;
        }
      }
      if (!!states[key]?.on) {
        for (let eventName in states[key].on) {
          if (
            !!eventName
          ) {

            if (states[key].on[eventName].actions !== "storeText" && states[key].on[eventName].actions !== "storeAttachment") {
              node.data.isEditable = false
            }
            if (!!states[key].on[eventName].errorMessage) {
              node.data.defaultMessage = states[key].on[eventName].errorMessage;
            }
            const id = Math.round(Math.random() * 100000)
            const edge = {
              data: {
                actions: states[key].on[eventName].actions,
              },
              id,
              markerEnd: { type: "arrowclosed", width: "25px", height: "25px" },
              selected: "",
              source: `${nodeId[key]}`,
              style: { stroke: "white" },
              target: `${nodeId[states[key].on[eventName].target]}`,
              type: "buttonedge",
            }
            if (eventName === "TRIGGER" && Object.keys(states[key].on).length === 1) {
              edge.data.transition = { english: undefined, hebrew: undefined }
            } else if (eventName === "TRIGGER" && Object.keys(states[key].on).length > 1) {
              edge.data.transition = { english: NO_TRANSITION_EVENT_NAME, hebrew: NO_TRANSITION_HEBREW_EVENT_NAME }
            } else {
              edge.data.transition = { english: eventName, hebrew: entohe[eventName] }
            }
            if (!!access && access.hasOwnProperty(key)) {
              if (access[key].hasOwnProperty("deleteEvents")) {
                edge.data.deleteEvents = access[key].deleteEvents;
              }
              if (access[key].hasOwnProperty("hebrewEventsEditable")) {
                edge.data.hebrewEventsEditable = access[key].hebrewEventsEditable;
              }
              if (access[key].hasOwnProperty("englishEventsEditable")) {
                edge.data.englishEventsEditable = access[key].englishEventsEditable;
              }
            }
            edges.push(edge);
          }
        }
      }
      nodes.push(node);
    }
    return { nodes, edges };
  } catch (error) {
    console.log(error.message);
  }
};