import { ROUTES } from "./utils/constant";
import AdminDashboard from "./pages/Dashboard/AdminDashboard";
import { Bot } from "./components/Flow/Bot";
import { BotFlow } from "./components/Flow/Flow"
import SignIn from "./pages/Login/SignIn";
import SignUpForm from "./pages/SignUpForm/SignUpForm";
import UserAnalytics from "./pages/Analytics/UserAnalytics";
import Users from "./pages/Users/Users";
import Chats from "./pages/Chats/Chats";
import AddonList from "./pages/AddOnList/AddOnList";
import AddonDetails from "./pages/AddonDetails/AddonDetails";
import { ResetPassword } from "./pages/ResetPassword/ResetPassword";
import { Thanks } from "./components/Thanks/Thanks";
import { UsersList } from "./components/UsersList/UsersList";
import GenerateApiKeys from "./pages/GenerateApiKeys";
import ProfilePage from "./pages/Profile/ProfilePage";
import { Navigate } from "react-router-dom";

export const adminRoutes = [
  {
    path: ROUTES.CREATE_BOT_FLOW,
    name: "Create Bot Flow",
    element: <BotFlow />,
  },
  {
    path: ROUTES.DASHBOARD,
    name: "Dashboard",
    element: <AdminDashboard />,
  },
  {
    path: ROUTES.EDIT_BOT_FLOW,
    name: "Edit Bot Flow",
    element: <BotFlow />,
  },
  {
    path: ROUTES.ADMIN,
    name: "Admin",
    element: <Navigate to={ROUTES.DASHBOARD} replace />,
  },
  {
    path: ROUTES.USER_ANALYTICS,
    name: "UserAnalytics",
    element: process.env.REACT_APP_SHOW_ANALYTICS === "true" ? <UserAnalytics /> : <Navigate to={ROUTES.DASHBOARD} replace />,
  },
  {
    path: ROUTES.USERS_DETAILS,
    name: "user_details",
    element: <Users />,
  },
  {
    path: ROUTES.USERS,
    name: "users",
    element: process.env.REACT_APP_SHOW_USERS === "true" ? <Users /> : <Navigate to={ROUTES.DASHBOARD} replace />,
  },
  {
    path: ROUTES.ADDON,
    name: "addon",
    element: <AddonList />,
  },
  {
    path: ROUTES.ADDON_DETAILS,
    name: "addon_details",
    element: <AddonDetails />,
  },
  {
    path: ROUTES.USER_CHATS,
    name: "UserChats",
    element: <UsersList />,
  },
  {
    path: ROUTES.CHAT,
    name: "Chats",
    element: process.env.REACT_APP_SHOW_CHAT === "true" ? <Chats /> : <Navigate to={ROUTES.DASHBOARD} replace />
  },
  {
    path: ROUTES.CHATS,
    name: "Chats",
    element: process.env.REACT_APP_SHOW_CHAT === "true" ? <Chats /> : <Navigate to={ROUTES.DASHBOARD} replace />
  },
  {
    path: ROUTES.GENERATE_API_KEYS,
    name: "Generate_Api_Keys",
    element: <GenerateApiKeys />
  },
  {
    path: ROUTES.PROFILE_PAGE,
    name: "profilePage",
    element: <ProfilePage />
  }
];

export const publicRoutes = [
  {
    path: ROUTES.BOT,
    name: "Bot",
    element: <Bot />,
  },
];

export const authRoutes = [
  {
    path: ROUTES.LOG_IN,
    name: "Login",
    element: <SignIn />,
  },
  {
    path: ROUTES.SIGN_UP,
    name: "Signup",
    element: <SignUpForm />,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    name: "ResetPassword",
    element: <ResetPassword />,
  },
  {
    path: ROUTES.THANKS,
    name: "thanks",
    element: <Thanks />,
  },
];
