import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

export const BasicSelect = ({
  items = [],
  isDisabled,
  onSelect,
  selected,
  title,
  size,
  width,
  height,
  isObject,

}) => {
  const handleChange = (event) => {
    onSelect(event.target.value);
  };

  return (
    <FormControl
      size={!!size ? size : "medium"}
      sx={{
        background: "white",
        borderRadius: 1,
        minWidth: !!width ? width : "150px",
      }}
    >
      <TextField
        select
        size={!!size ? size : "medium"}
        label={title}
        value={selected || ""} 
        onChange={handleChange}
        disabled={isDisabled}
        sx={{
          height: height ? height : 40,
          "& .MuiInputBase-input": {
            paddingY: 1.055, 
            fontSize: "14px",
          },
          "& fieldset": {
            borderColor: "#C8C8C8", // Set the default border color
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#089BAE", // Border color on hover
            },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#089BAE", // Border color on input focus
            },
        }}
        InputLabelProps={{ 
          style: {
            zIndex: 0, 
            color: "#3E3E3E"
          },
        }}
      >
        {isObject
          ? Object.keys(items).map((key) => (
              <MenuItem key={key} value={key}>
                {items[key]}
              </MenuItem>
            ))
          : items.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
      </TextField>
    </FormControl>
  );
};