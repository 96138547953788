import { Button, Card, CardMedia, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES, enabledAddons } from "../../utils/constant";

export const AddonCard = ({ isActivated, description, name, logo }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        p: "10px",
        background: "#FFFFFF",
        boxShadow: "5px 5px 10px #089BAE33",
        opacity: 1,
        border: "1px solid",
        borderColor: "#C8C8C8",
        minWidth: 230,
        height: 140,
      }}
    >
      <Typography
        component={"div"}
        sx={{
          height: "100%",
          width: "100%",
          padding: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            height: "100%",
            gap: 1,
          }}
        >
          <CardMedia
            component="img"
            image={logo}
            alt="Paella dish"
            sx={{
              borderRadius: "50%",
              height: "70px",
              width: "70px",
            }}
          />
          <Typography
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography
              variant="body"
              component={"div"}
              sx={{
                textTransform: "capitalize",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                font: "normal normal 600 14px/17px 'Graphie, SemiBold'",
                letterSpacing: "0px",
                color: "#089BAE",
                mb: "8px",
              }}
            >
              {name}
              {isActivated && (
                <Typography
                  sx={{
                    background: "green",
                    height: "fit-content",
                    px: "8px",
                    py: "3px",
                    textAlign: "center",
                    borderRadius: "10px",
                    font: "normal normal normal 10px/14px 'Segoe UI'",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                  }}
                >
                  Activated
                </Typography>
              )}
            </Typography>
            <Typography
              component={"div"}
              sx={{
                // height: "fit-content",
                textDecoration: "justify",
                // fontSize: 12,
                font: "normal normal normal 12px/16px 'Segoe UI'",
                letterSpacing: "0px",
                color: "#3E3E3E",
              }}
            >
              {description}
            </Typography>
          </Typography>
        </Typography>
        <Button
          variant="contained"
          disabled={!enabledAddons.has(name.toLowerCase())}
          fullWidth
          onClick={() => {
            navigate(`${ROUTES.ADDON}/${name}`);
          }}
          sx={{
            textTransform: "none",
            height: "30px",
            background: "#089BAE 0% 0% no-repeat padding-box",
            borderRadius: "5px",
            py: "8px",
            "&:hover": {
              background: "#148695",
            },
          }}
        >
          {enabledAddons.has(name.toLowerCase()) ? 'Connect' : 'Please contact Botifier team for activation'}
        </Button>
      </Typography>
    </Card>
  );
};
