import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { styled } from '@mui/material/styles';

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#121858',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize:'large'
        
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
       border: 1,
    },
}));


export const Tables = ({ rows=[], columns=[]}) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <Paper sx={{ width: '100%',  overflow: 'hidden',}}>
            <TableContainer >
                <Table stickyHeader aria-label="sticky table" >
                    <TableHead sx>
                        <TableRow>
                            {columns.map((column, index) => (
                                <StyledTableCell
                                    key={index}
                                    align={'left'}
                                    style={{ minWidth: 130 }}
                                >
                                    {column.headerName}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => {
                                return (
                                    
                                    <StyledTableRow hover role="checkbox" tabIndex={-1} key={index} classes={{ maxHeight: 1 }}>
                                        {columns.map((column, ind) => {
                                                        
                                            return (
                                                <StyledTableCell key={ind} align={'left'}>
                                                    {!!row[column.field] ? row[column.field]: '--'}
                                                </StyledTableCell>
                                            );                                      
                                            
                                        })}
                                    </StyledTableRow>
                                );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
           {/* {!!columns.length && <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />} */}
        </Paper >
    );
}
