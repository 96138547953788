// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotFound_NotFound__ocYd3 {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n}\n.NotFound_NotFound__ocYd3 p {\n  font-size: 1.2rem;\n}\n.NotFound_NotFound__ocYd3 p:not(:last-child) {\n  margin: 0 0 1rem 0;\n}", "",{"version":3,"sources":["webpack://./src/components/NotFound/NotFound.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACE,iBAAA;AACN;AACM;EACE,kBAAA;AACR","sourcesContent":[".NotFound{\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n  \n    p {\n      font-size: 1.2rem;\n  \n      &:not(:last-child) {\n        margin: 0 0 1rem 0;\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NotFound": "NotFound_NotFound__ocYd3"
};
export default ___CSS_LOADER_EXPORT___;
