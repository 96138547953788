import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { theme } from "../../utils/theme";
import { BootstrapTooltip } from "../UI/Tooltip";

export const NavigationList = ({ items, isDivider, isLink, isOpen }) => {
  const [navigationListItem, setNavigationsListItem] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const location = useLocation();

  useEffect(() => {
    if (items.length) {
      setNavigationsListItem([...items]);
    }
  }, [items]);

  useEffect(() => {
    let currentActiveIndx = -1;
    const currentPath = location.pathname;

    items.forEach((item, index) => {
      if (currentPath.includes(item.path)) {
        currentActiveIndx = index;
      }
    });
    setActiveIndex(currentActiveIndx);
  }, [location]);

  const handleListItemClick = (index) => {
    setActiveIndex(index);
  };
  return (
    <>
      {isDivider && (
        <Divider sx={{ background: theme.palette.primary.background }} />
      )}
      <List sx={{ p: 0 }}>
        {navigationListItem.map((item, index) => (
          item.show ?
          <>
            {isLink ? (
              <Link
                to={item.path}
                key={index}
                style={{ textDecoration: "none" }}
              >
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: isOpen ? "initial" : "center",
                      px: 2.5,
                      "&:hover": {
                        color: "#3E3E3E",
                      },

                    }}
                    onClick={() => {
                      handleListItemClick(index);
                    }}
                  >
                    {!isOpen ? (
                      <BootstrapTooltip
                        title={item.title}
                        arrow
                        placement="right"
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: isOpen ? 3 : "0px",
                            justifyContent: "center",
                            color: activeIndex === index ? "#000000" : "#121858",
                            opacity: activeIndex === index ? 1 : 0.5,
                            '&:hover': {
                              color: "#3E3E3E"
                            }
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                      </BootstrapTooltip>
                    ) : (
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isOpen ? 3 : "0px",
                          justifyContent: "center",
                            color: activeIndex === index ? "#000000" : "#121858",
                            opacity: activeIndex === index ? 1 : 0.5,
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                    )}
                    {isOpen && (
                      <ListItemText
                        primary={item.name}
                        sx={{
                          color: activeIndex === index ? "#000000" : "#121858",
                          opacity: activeIndex === index ? 1 : 0.5,
                          fontSize: '16px', // Adjust the font size as needed
                          fontFamily: 'Arial, sans-serif', // Adjust the font family as needed
                          fontWeight: 'normal', // Adjust the font weight as needed
                        }}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              </Link>
            ) : (
              <ListItem key={index} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: isOpen ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <BootstrapTooltip title={item.title} arrow placement="right">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: isOpen ? 3 : "auto",
                        justifyContent: "center",
                          color: activeIndex === index ? "#000000" : "#121858",
                          opacity: activeIndex === index ? 1 : 0.5,
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                  </BootstrapTooltip>
                  <ListItemText
                    primary={item.name}
                    sx={{
                      opacity: isOpen ? 1 : 0,
                      color: "white",
                      "&:hover": {
                        color: "#3E3E3E",
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {isDivider && (
              <Divider sx={{ background: theme.palette.primary.background }} />
            )}
          </>: 
          <></>
        ))}
      </List>
    </>
  );
};
