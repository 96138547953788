// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppNavigation_headerTitle__Jgidn {\n  text-decoration: none;\n  padding-top: 1px;\n  padding-left: 10px;\n  font-weight: bolder;\n  font-size: xx-large;\n}\n.AppNavigation_headerTitle__Jgidn:hover {\n  color: aliceblue;\n}\n\n.AppNavigation_headerIcon__MUmqZ {\n  padding: 5px;\n  width: 45px;\n  height: 45px;\n  margin-bottom: 3px;\n}", "",{"version":3,"sources":["webpack://./src/components/AppNavigation/AppNavigation.module.scss"],"names":[],"mappings":"AAAA;EAEI,qBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;AAAJ;AAEI;EACI,gBAAA;AAAR;;AAIA;EACI,YAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AADJ","sourcesContent":[".headerTitle {\n   // color: white;\n    text-decoration: none;\n    padding-top: 1px;\n    padding-left: 10px;\n    font-weight: bolder;\n    font-size: xx-large;\n\n    &:hover {\n        color: aliceblue;\n    }\n}\n\n.headerIcon {\n    padding: 5px;\n    width: 45px;\n    height: 45px;\n    margin-bottom: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerTitle": "AppNavigation_headerTitle__Jgidn",
	"headerIcon": "AppNavigation_headerIcon__MUmqZ"
};
export default ___CSS_LOADER_EXPORT___;
