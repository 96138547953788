import * as React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../utils/constant';
import styles from './NotFound.module.scss';

const NotFound = () => {
  return (
    <div className={styles.NotFound}>
      <p>The page you were looking for does not exist.</p>
      <p>You may have mistyped the address or the page may have moved. <Link to={ROUTES.DASHBOARD} replace>Go To Dashboard</Link></p>

    </div>
  );
};

export default React.memo(NotFound);
