import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { emailValidation, regularCharValidation } from "../../utils/helper";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { endpoints } from "../../api/endpoints";
import styles from "./Signup.module.scss";
import BotImage from "../Images/bot_image.png";
import HeaderIcon from "../Images/headerIcon.png";
import { ROUTES } from "../../utils/constant";

const useStyles = makeStyles((theme) => ({
  // "@global": {
  //     body: {
  //         backgroundColor: theme.palette.common.white
  //     }
  // },
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    textTransform: "none",
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "red",
    color: "white",
  },
  signUpTypography: {
    color: theme.palette.secondary.main,
  },
}));

const SignUpForm = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidFirstName, setIsValidFirstName] = useState(true)
  const [isValidLastName, setIsValidLastName] = useState(true)
  const [username, setUserName] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [verificationPage, setVerificationPage] = useState(false);
  const [show, setShow] = useState(false);

  const handleEmailChange = (event) => {
    setUserName(event.target.value.trim());
    setIsValidEmail(emailValidation(event.target.value));
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value.trim());
    setIsValidFirstName(regularCharValidation(event.target.value));
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value.trim());
    setIsValidLastName(regularCharValidation(event.target.value));
  };

  async function signUpUser(credentials) {
    axios
      .post(endpoints.bot.signup, { ...credentials })
      .then((res) => {
        toast.success(res.data);
        setVerificationPage(true);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  }

  const handleSubmit = async (e) => {
    setShow(true);
    e.preventDefault();
    if (!isValidEmail) {
      toast.error("Please enter Valid email");
      return;
    }
    if(!isValidFirstName || !isValidLastName){
      toast.error("Name fields should contain regular characters only")
      return
    }
    if (password1 !== password2) {
      toast.error("Passwords do not match.Please try again");
      return;
    }
    const credentials = {
      first_name: firstName,
      last_name: lastName,
      email: username,
      password: password1,
    };
    await signUpUser(credentials);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className={styles.MainContainer}>
        <div>
          <img src={BotImage} className={styles.bg_image}></img>
        </div>

        <div className={styles.container}>
          <div className={styles.signUpContainer}>
            <div className={styles.upperSection}>
              <div className={styles.botifier}>
                <div className={styles.botifierDiv}>
                  <img src={HeaderIcon} className={styles.headerIcon}></img>
                  Botifier
                </div>
              </div>
              {!show && (
                <div className={styles.welcomeHeader}>Create new account</div>
              )}
            </div>
            {!verificationPage ? (
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      error={!isValidFirstName}
                      autoFocus
                      className={styles.inputElement}
                      value={firstName}
                      onChange={(handleFirstNameChange)}
                      style={{ background: "white", borderRadius: "5px" }}
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#089BAE", // Apply your custom border color here
                        },
                        "& .MuiInputLabel-outlined.Mui-focused": {
                          color: "#089BAE", // Apply your custom label color here
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="lname"
                      className={styles.inputElement}
                      value={lastName}
                      error={!isValidLastName}
                      onChange={handleLastNameChange}
                      style={{ background: "white", borderRadius: "5px" }}
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#089BAE", // Apply your custom border color here
                        },
                        "& .MuiInputLabel-outlined.Mui-focused": {
                          color: "#089BAE", // Apply your custom label color here
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{ width: "100%", backgroundColor: "white" }}
                      variant="outlined"
                      className={styles.inputElement}
                    >
                      <InputLabel
                        htmlFor="outlined-adornment-password"
                        sx={{
                          "&.Mui-focused": {
                            color: "#089BAE",
                          },
                        }}
                      >
                        Email Address
                      </InputLabel>
                      <OutlinedInput
                        required
                        fullWidth
                        id="email"
                        type="text"
                        label="Email Address"
                        className={styles.userNameInput}
                        value={username}
                        onChange={handleEmailChange}
                        error={!isValidEmail}
                        sx={{
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#089BAE",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{ width: "100%", backgroundColor: "white" }}
                      className={styles.inputElement}
                    >
                      <InputLabel
                        htmlFor="outlined-adornment-password"
                        sx={{
                          "&.Mui-focused": {
                            color: "#089BAE",
                          },
                        }}
                      >
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword1 ? "text" : "password"}
                        className={styles.userNameInput}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword1(!showPassword1)}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword1 ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        value={password1}
                        onChange={(e) => setPassword1(e.target.value.trim())}
                        sx={{
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#089BAE",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{ width: "100%", backgroundColor: "white" }}
                      className={styles.inputElement}
                    >
                      <InputLabel
                        htmlFor="outlined-adornment-password"
                        sx={{
                          "&.Mui-focused": {
                            color: "#089BAE",
                          },
                        }}
                      >
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword2 ? "text" : "password"}
                        className={styles.userNameInput}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword2(!showPassword2)}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword2 ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Confirm Password"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value.trim())}
                        error={
                          !password2.length > 8 && !password1 === password2
                        }
                        sx={{
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#089BAE",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleSubmit}
                  style={{ backgroundColor: "#089BAE", color: "#ffff" }}
                >
                  Signup
                </Button>
                <Grid container justify="center">
                  <Grid item>
                    <span className={styles.have_account}>
                      Already have an account?
                    </span>
                    <span
                      className={styles.click_here}
                      onClick={() => {
                        navigate(ROUTES.LOG_IN);
                      }}
                    >
                      Sign in
                    </span>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <div className={styles.forgetPage}>
                <div className={styles.welcomeHeader}>Check your inbox.</div>
                <span className={styles.have_account}>
                  Click the link we sent to {username} to complete your account
                  set-up.
                </span>
                <span
                  className={styles.click_here}
                  onClick={() => {
                    navigate(ROUTES.LOG_IN);
                    setShow(false);
                  }}
                >
                  Sign in
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpForm;
