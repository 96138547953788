import { Button, InputLabel, TextField, Typography } from "@mui/material";
import { DialogModal } from "../Modal/DialogModal";
import { theme } from "../../utils/theme";
import styles from "../AddonDetailsForm/AddonDetailsForm.module.scss";
import { useParams } from "react-router-dom";

export const AddonDetailsForm = ({
  open,
  onClose,
  inputList,
  inputs,
  setInputs,
  isValid,
  validate,
  errorMessage,
  children,
}) => {
  const { addonName } = useParams();

  return (
    <>
      <DialogModal open={open} onClose={onClose}>
        <Typography component={"div"} className={styles.container}>
          <div className="notButtonContainer">
            <Typography
              component={"div"}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                mb: "20px",
                font: "normal normal 600 20px/24px 'Graphie, SemiBold'",
                letterSpacing: "0px",
                color: "#089BAE",
              }}
            >
              Configuration
            </Typography>
            {children}
            {inputList.map((item) => {
              return (
                <Typography
                  component={"div"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    mb: "15px",
                    gap: 0.6,
                  }}
                >
                  <InputLabel
                    sx={{
                      textTransform: "capitalize",
                      font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
                      letterSpacing: "0px",
                      color: "#3E3E3E",
                    }}
                  >
                    {item.label}
                  </InputLabel>
                  <TextField
                    sx={{
                      "& input::placeholder": {
                        font: "normal normal normal 12px/16px 'Graphie, Regular'",
                        letterSpacing: "0px",
                        color: "#3E3E3E",
                        // mr:"20px"
                      },
                      "& fieldset": {
                        borderColor: "#C8C8C8", // Set the default border color
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#089BAE", // Border color on hover
                        },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#089BAE", // Border color on input focus
                        },
                    }}
                    inputProps={{
                      style: {
                        height: 18,
                        width: "100%",
                        padding: 9,
                        background: "white",
                        borderRadius: "5px",
                      },
                      placeholder: "Enter here......",
                    }}
                    value={
                      !!inputs[item.fieldName]
                        ? inputs[item.fieldName].value
                        : ""
                    }
                    onChange={(event) => {
                      setInputs({
                        ...inputs,
                        [item.fieldName]: {
                          value: event.target.value,
                          in: item.in,
                        },
                      });
                    }}
                  />
                </Typography>
              );
            })}
            {!isValid && (
              <Typography
                sx={{
                  color: theme.palette.secondary.main,
                  textAlign: "center",
                  mb: 1,
                }}
              >
                {errorMessage}
              </Typography>
            )}
          </div>
          <div className="buttonContainer">
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                mt: "30px",
                width: "100%",
                gap: "200px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  color: "#3E3E3E",
                  background: "#ECECEC 0% 0% no-repeat padding-box",
                  borderRadius: "5px",
                  font: "normal normal 600 14px/19px 'Segoe UI'",
                  letterSpacing: "0px",
                  py: "8px",
                  px: "55px",
                  "&:hover": {
                    background: "#b4b5b5",
                  },
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  font: "normal normal 600 14px/19px 'Segoe UI'",
                  color: "#FFFFFF",
                  background: "#089BAE",
                  borderRadius: "5px",
                  px: "55px",
                  py: "8px",
                  "&:hover": {
                    background: "#148695",
                  },
                }}
                onClick={validate}
              >
                {addonName === "QRCode" ? "Download" : "Submit"}
              </Button>
            </Typography>
          </div>
        </Typography>
      </DialogModal>
    </>
  );
};
