import { Button, Typography } from "@mui/material";
import bannerImage from "../Images/banner_image.png";
import React, { useEffect, useState } from "react";
import { BasicSelect } from "../../components/Select/Select";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "../../components/DataTable/DataTable";
import { getBots, getUsers, getAllUsers } from "../../api";
import { ROUTES } from "../../utils/constant";
import DashboardBanner from "../../components/Banner/DashboardBanner";
import moment from "moment";
import { connect } from "react-redux";
import { setUserChatSelectedSmd } from "../../reducers/userChatSelectedSmd";

const Users = ({ userChatSelectedSmd, setUserChatSelectedSmd }) => {
  const navigate = useNavigate();
  const [smds, setSmds] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const { smdId } = useParams();
  const [smdName, setSmdName] = useState("");

  const handlePageChange = (params) => {
    setPage(params);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
    setPage(0);
  };

  const onSelectSmd = (value) => {
    setUserChatSelectedSmd(value);
  };

  const columns = [
    { field: "uid", headerName: "User ID", flex: 1 },
    { field: "chatPlatform", headerName: "Chat platform", flex: 1 },
    { field: "createdAt", headerName: "Created At", flex: 1 },
    { field: "updatedAt", headerName: "Updated At", flex: 1 },
    {
      field: "archiveChats",
      headerName: "Archive Chats",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              width: "fit-content",
              pl: 4,
              pr: 4,
              borderRadius: 1,
              background: "#089BAE",
              "&:hover": {
                background: "#148695",
              },
            }}
            onClick={() => {
              handleButtonClick(params.row);
            }}
          >
            Open
          </Button>
        );
      },
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const handleButtonClick = (param) => {
    navigate(`${ROUTES.CHATS}/${param.uid}`, {
      state: {
        botId: userChatSelectedSmd,
      },
    });
  };

  const fetchSMD = async () => {
    const { smd } = await getBots();
    const smdList = smd.map((bot) => {
      return {
        isActive: bot.isActive,
        name: bot.smdName,
        id: bot._id,
        smd: bot.smd,
      };
    });
    setSmds(smdList);
    setSmdName(smdList.name);
    fetchLiveBots(smdList[0].id, page); 
    userChatSelectedSmd? setUserChatSelectedSmd(userChatSelectedSmd, page): setUserChatSelectedSmd(smdList[0].id, page);
  };

  const getModifiedDate = (dateString) => {
    const date = moment(dateString).format("DD-MM-YYYY");
    return date;
  };

  const fetchAllUsers = async (id) => {
    const response = await getAllUsers(id);

    setRowCount(response.count);
  };

  function createButton() {
    const button = document.createElement("button");
    button.textContent = "Click Me";
    return button;
  }

  const fetchLiveBots = async (id, offset = 0) => {
    if (id) {
      setLoading(true);
      const response = await getUsers(id, offset);
      const modifiedResponse = response.map((value) => {
        return {
          chatPlatform: value.chatPlatform,
          createdAt: getModifiedDate(value.createdAt),
          updatedAt: getModifiedDate(value.updatedAt),
          uid: value.uid,
          id: value._id,
          button: createButton(),
        };
      });
      setUserInfo(modifiedResponse);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSMD();
    onSelectSmd(smdId);
  }, [smdId]);

  useEffect(() => {
    try {
      if (!!userChatSelectedSmd) {
        fetchAllUsers(userChatSelectedSmd);
        fetchLiveBots(userChatSelectedSmd);
      }
    } catch (error) {
      console.log("Error occurred while finding Users for the bot");
    }
  }, [userChatSelectedSmd]);

  useEffect(() => {
    fetchLiveBots(userChatSelectedSmd, page);
  }, [page, pageSize]);

  const [rowCountState, setRowCountState] = useState(rowCount);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  return (
    <>
      <DashboardBanner image={bannerImage} imageWidth={40} imageHegiht={160}>
        <Typography
          sx={{
            height: 160,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <h5>Select Bot</h5>
          <Typography varient="p" component={"div"}>
            Botifier's AI-powered bot builder to easily create bots and
            conversational workflows.
          </Typography>
          <Typography component={"div"} sx={{ maxWidth: "320px" }}>
            <BasicSelect
              items={smds}
              onSelect={onSelectSmd}
              selected={userChatSelectedSmd}
              title={"Bots"}
              label={"Bots"}
            />
          </Typography>
        </Typography>
      </DashboardBanner>
      <Typography component={"div"} sx={{ mt: "2rem" }}>
        <DataTable
          rows={userInfo}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          pageSize={pageSize}
          page={page}
          rowCount={rowCountState}
          loading={loading}
          userChatSelectedSmd={userChatSelectedSmd}
          smdName={smdName}
          columns={columns}
          height={400}
        />
      </Typography>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userChatSelectedSmd: state.userChatSelectedSmd,
  };
};

const mapDispatchToProps = {
  setUserChatSelectedSmd,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);