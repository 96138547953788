import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#121858',  //indigo 800
            dark: '#002884',
            contrastText: '#fff',
            background: '#e8eaf6',
            lightGreen:"#47c778",
            red:'#b30a0a',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
            lightGreen:"#258d4d",
            red:'#920505',
        },
    },
});