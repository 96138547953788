import { Routes, Route, Link, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { adminRoutes, authRoutes, publicRoutes } from "./routes";
import { Authenticated } from "./components/Authenticated/Authenticated";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import "./fonts.scss";
import AppNavigation from "./components/AppNavigation/AppNavigation";
import { useEffect } from "react";
import { useAuth } from "./utils/auth";
import { ROUTES } from "./utils/constant";
import NotFound from "./components/NotFound/NotFound";
import AdminRoutes from "./routes/AdminRoutes";
import AuthRoutes from "./routes/AuthRoutes";
import Home from "./pages/Home/Home";
import { connect, useDispatch } from "react-redux";
import { getActivatedApis, getAddons, getAPIKeys, getBots, getBotTemplates, getUser } from "./routines";


function App({ user }) {
  const dispatch = useDispatch();
  // const auth = useAuth();

  // useEffect( () => {
  //   !Object.keys(user).length === 0 && dispatch(getUser.trigger())
  //   console.log("user", user)
  // }, [])

  useEffect(() => {
    if (!!localStorage.getItem("authToken")) {
      dispatch(getUser.trigger())
    }
  }, [])

  useEffect(() => {
    if (!!user.email) {
      dispatch(getBots.trigger());
      dispatch(getAddons.trigger());
      dispatch(getActivatedApis.trigger());
      dispatch(getAPIKeys.trigger());
      dispatch(getBotTemplates.trigger())
    }
  }, [user])

  return (
    <div className="App">
      <ToastContainer position="top-right" newestOnTop autoClose={2000} />
      <AppNavigation>
        <>
          <Link path={ROUTES.LOG_IN} relative="/" id="2" />
          <Routes>
            <Route path={ROUTES.ROOT} element={<Home />} />
            <Route path={ROUTES.AUTH} element={<AuthRoutes />}>
              {authRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Route>
            <Route element={<Authenticated />}>
              <Route path={ROUTES.ADMIN} element={<AdminRoutes />}>
                {adminRoutes.map((route, index) => (
                  <Route key={index} path={route.path} element={route.element} />
                ))}
              </Route>
            </Route>
            <Route path={ROUTES.PUBLIC} element={<AuthRoutes />}>
              {publicRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </>
      </AppNavigation>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(App);


