import { takeEvery, takeLatest, put, call, take } from "redux-saga/effects";
import {
  createBot,
  deleteBot,
  getBotNewUsers,
  getBots,
  getBotVersions,
  getBotTemplates,
  getBotUsers,
  getBotUses,
  getCSVReport,
  getSmdfromPrompt,
  updateBot,
  reloadBot,
} from "../routines";
import { Apis } from "../api"; // Replace with your actual API function
import { toast } from "react-toastify";
import { ROUTES } from "../utils/constant";

function* createBotSaga({ payload }) {
  try {
    yield put(createBot.request());
    const response = yield call(Apis.createBot, payload);
    yield put(createBot.success());
    toast.success("Bot created successfully");
    window.location.replace(ROUTES.DASHBOARD);
    yield put(getBots.trigger());
  } catch (error) {
    yield put(createBot.failure(error.message));
    toast.error(error.message);
  } finally {
    yield put(createBot.fulfill());
  }
}
function* updateBotSaga({ payload }) {
  try {
    yield put(updateBot.request());
    const response = yield call(Apis.updateBot, payload);
    yield put(updateBot.success());
    toast.success(response.message);
    window.location.replace(ROUTES.DASHBOARD);
    yield put(getBots.trigger());
  } catch (error) {
    yield put(createBot.failure(error.message));
    toast.error(error.message);
  } finally {
    yield put(createBot.fulfill());
  }
}
function* getBotVersionsSaga({ payload }) {
  try {
    yield put(getBotVersions.request());
    const response = yield call(Apis.getBotVersions, payload);
    yield put(getBotVersions.success(response.smd));
  } catch (error) {
    yield put(getBotVersions.failure(error.message));
  } finally {
    yield put(getBotVersions.fulfill());
  }
}
function* getBotsSaga() {
  try {
    yield put(getBots.request());
    const response = yield call(Apis.getBots);
    yield put(getBots.success(response.smd));
  } catch (error) {
    yield put(getBots.failure(error.message));
  } finally {
    yield put(getBots.fulfill());
  }
}
function* deleteBotSaga({ payload }) {
  try {
    yield put(deleteBot.request());
    const response = yield call(Apis.deleteBot, payload);
    yield put(deleteBot.success());
    toast.success("Bot deleted successfully");
    yield put(getBots.trigger());
  } catch (error) {
    yield put(deleteBot.failure());
  } finally {
    yield put(deleteBot.fulfill());
  }
}
function* getBotUsesSaga({ payload }) {
  try {
    yield put(getBotUses.request());
    const response = yield call(Apis.getBotUses, payload);
    yield put(getBotUses.success(response));
  } catch (error) {
    yield put(getBotUses.failure());
  } finally {
    yield put(getBotUses.fulfill());
  }
}
function* getBotNewUsersSaga({ payload }) {
  try {
    yield put(getBotNewUsers.request());
    const response = yield call(Apis.getBotNewUsers, payload);
    yield put(getBotNewUsers.success(response));
  } catch (error) {
    yield put(getBotNewUsers.failure());
  } finally {
    yield put(getBotNewUsers.fulfill());
  }
}
function* getBotUsersSaga({ payload }) {
  try {
    yield put(getBotUsers.request());
    const response = yield call(Apis.getBotUsers, payload);
    yield put(getBotUsers.success(response));
  } catch (error) {
    yield put(getBotUsers.failure());
  } finally {
    yield put(getBotUsers.fulfill());
  }
}

function* getCSVReportSaga({ payload }) {
  try {
    yield put(getCSVReport.request());
    const response = yield call(Apis.getCSVReport, payload);
    yield put(getCSVReport.success());
    toast.success(response.message)
  } catch (error) {
    yield put(getCSVReport.failure());
  } finally {
    yield put(getCSVReport.fulfill());
  }
}

function* getBotTemplatesSaga() {
  try {
    yield put(getBotTemplates.request());
    const response = yield call(Apis.getBotTemplates);
    yield put(getBotTemplates.success(response));
  } catch (error) {
    yield put(getBotTemplates.failure());
  } finally {
    yield put(getBotTemplates.fulfill());
  }
}

function* getSmdfromPromptSaga({ payload }) {
  try {
    yield put(getSmdfromPrompt.request());
    const response = yield call(Apis.getSmdfromPrompt, payload);
    yield put(getSmdfromPrompt.success(response));
  } catch (error) {
    yield put(getSmdfromPrompt.failure());
  } finally {
    yield put(getSmdfromPrompt.fulfill());
  }
}

function* reloadBotSaga() {
  try {
    yield put(reloadBot.request());
    const response = yield call(Apis.reloadBot);
    yield put(reloadBot.success());
    toast.success("Bot reloaded successfully")
  } catch (error) {
    yield put(reloadBot.failure());
    toast.error("Something went wrong, Please try again after some time!")
  } finally {
    yield put(reloadBot.fulfill());
  }
}
export function* watchBotSaga() {
  yield takeLatest(getBots.TRIGGER, getBotsSaga);
  yield takeLatest(getBotVersions.TRIGGER, getBotVersionsSaga);
  yield takeLatest(deleteBot.TRIGGER, deleteBotSaga);
  yield takeLatest(createBot.TRIGGER, createBotSaga);
  yield takeLatest(getBotUses.TRIGGER, getBotUsesSaga);
  yield takeLatest(getBotNewUsers.TRIGGER, getBotNewUsersSaga);
  yield takeLatest(getBotUsers.TRIGGER, getBotUsersSaga);
  yield takeLatest(getCSVReport.TRIGGER, getCSVReportSaga)
  yield takeLatest(getBotTemplates.TRIGGER, getBotTemplatesSaga)
  yield takeLatest(getSmdfromPrompt.TRIGGER, getSmdfromPromptSaga)
  yield takeLatest(updateBot.TRIGGER, updateBotSaga)
  yield takeLatest(reloadBot.TRIGGER, reloadBotSaga)
}
