import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


export const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#6E6E6E",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#6E6E6E",
        color: '#ECECEC',
    },
}));