import axios from "axios";
import { toast } from "react-toastify";
import { ROUTES } from "./constant";

export const emailValidation = (email) => {
  if (
    email.match(
      /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const regularCharValidation = (name) => {
  console.log("name", name)
    return name.match(
      /^[A-Za-z]+$/
    )
}

export const convertToTitleCase = (inputString) => {
  return inputString
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const sendRequest = async ({
  url,
  method = "GET",
  body,
  params = {},
  headers = {},
}) => {
  // const currentDate = new Date();
  // const time_zone = (currentDate.getTimezoneOffset() / 60).toString();

  try {
    const response = await axios({
      url: process.env.REACT_APP_BACKEND_URL + url,
      method,
      data: body,
      params: {
        ...params,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...headers,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("userEmail");
      window.location = ROUTES.LOG_IN;
    }
    throw {
      message: error.response.data.message,
      status: error.response.status,
      error: error.response.data.error,
    };
  }
};

export const copyContent = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success("Copied", { autoClose: 10 });
  } catch (err) {
    toast.error("Failed to copy: ");
    console.log("Copy Error", err);
  }
};

export const getFromDate = (date, value) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (value === "DDMMM") {
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];

    return `${day}${month}`;
  } else if (value === "month") {
    return months[date.getUTCMonth()];
  } else if (value === "Month") {
    return Months[date.getUTCMonth()];
  } else if (value === "year") {
    return date.getFullYear();
  } else  if(value=== 'DDMMMYYYY'){
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }else{
    const day = date.getUTCDate();
    const month = date.getUTCMonth()+1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
};
