import React, { useEffect, useState } from "react";
import { AddonDetailsForm } from "../../AddonDetailsForm/AddonDetailsForm";
import { ActivatedApis } from "../../ApiComponent/ActivatedApis";
import { Button, Typography } from "@mui/material";
import { Apis, activateAddon } from "../../../api";
import { ApiComponent } from "../../ApiComponent/ApiComponent";
import { useDispatch, useSelector } from "react-redux";
import { getActivatedApis } from "../../../routines";
import { DeleteDialogBox } from "../../DeleteDialogBox/DeleteDialogBox";
import { useParams } from "react-router-dom";
import { theme } from "../../../utils/theme";
import { toast } from "react-toastify";


const ApiAddonDetails = ({
  inputList,
  smdList,
  selectedSmd,
  commonOnSelectSmd,
  inputs,
  setInputs,
  isBotSelect,
  openDetailForm,
  addonData,
  handleDeactivateAllConfirmation,
  commonHandleOpenDetailForm,
  commonOnSubmit,
  isValid,
  setIsValid,
  commonValidate,
  errorMessage,
  fetchAddonData,
  commonDeactivateAllAddon,
  isDeactivateAllConfirmation,
}) => {
  const [apiDetail, setApiDetail] = useState(null);
  const [addonActivated, setAddonActivated] = useState(false);
  const dispatch = useDispatch();
  const activatedApis = useSelector((state) => state.activatedApis);
  const { addonName } = useParams();

  const handleOpenDetailForm = (apiIndex) => {
    commonHandleOpenDetailForm();
    setApiDetail(activatedApis[apiIndex]);
  };

  const getApis = async () => {
    dispatch(getActivatedApis.trigger());
  };

  const handleAddonActivate = async () => {
    try {
      if (addonName) {
        await activateAddon({ name: addonName });
        fetchAddon();
        getApis();
        toast.success("Addon Activated");
      } else {
        toast.error("Addon is Invalid ");
      }
    } catch (error) {
      toast.error(error?.data?.messsage);
    }
  };

  const fetchAddon = async () => {
    const { activatedAddon } = await Apis.getBotAddonsList();
    if (activatedAddon.includes(addonName)) {
      setAddonActivated(true);
    } else {
      setAddonActivated(false);
    }
  };

  const deactivateAllAddon = async () => {
    try {
      await commonDeactivateAllAddon();
      fetchAddon();
      getApis();
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchAddon();
    getApis();
  }, [addonData]);

  return (
    <div>
      <Typography
        component="div"
        sx={{
          mb: 5,
          pb: 3,
          borderBottom: "1px solid ",
          borderColor: theme.palette.primary.main,
        }}
      >
        <Typography
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              width: "45%",
            }}
          >
            <>
              {addonActivated ? (
                <>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      font: "normal normal 600 14px/19px 'Segoe UI'",
                      color: "#FFFFFF",
                      background: "#089BAE",
                      borderRadius: "5px",
                      "&:hover": {
                        background: "#148695",
                      },
                    }}
                    onClick={handleOpenDetailForm}
                  >
                    Add Api
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      font: "normal normal 600 14px/19px 'Segoe UI'",
                      color: "#FFFFFF",
                      background: "#CC0000",
                      borderRadius: "5px",
                      py: "9px",
                      ":hover": {
                        backgroundColor: "#920505",
                      },
                    }}
                    onClick={handleDeactivateAllConfirmation}
                  >
                    Deactivate
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "20px",
                    textTransform: "capitalize",
                  }}
                  onClick={handleAddonActivate}
                >
                  Activate
                </Button>
              )}
            </>
          </Typography>
        </Typography>
      </Typography>
      {addonActivated && addonData?.editOnFrontend && (
        <>
          <ActivatedApis
            open={openDetailForm}
            onClose={handleOpenDetailForm}
            activatedApis={activatedApis}
            getApis={getApis}
          />
          {openDetailForm && addonData?.editOnFrontend && (
            <ApiComponent
              apiDetail={apiDetail}
              open={openDetailForm}
              onClose={handleOpenDetailForm}
              getApis={getApis}
              fetchAddonData={fetchAddonData}
            />
          )}
          <DeleteDialogBox
            open={isDeactivateAllConfirmation}
            onClose={handleDeactivateAllConfirmation}
            onDelete={deactivateAllAddon}
            message={
              <span>
                Are you sure, <br /> do you want to deactivate all ?
              </span>
            }
          />
        </>
      )}
    </div>
  );
};

export default ApiAddonDetails;
