import { useContext, useMemo, useState } from "react";

import {
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import RightArrowIcon from "@mui/icons-material/MenuOpen.js";
import { toast } from "react-toastify";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VueIcon from "@mui/icons-material/ViewModule.js";
import EditIcon from "@mui/icons-material/Edit";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// import { theme } from "./../../utils/theme.js";
import { theme } from "../../../utils/theme.js";
import { copyContent } from "../../../utils/helper.js";
import { FlowContext } from "../../../store/flow-context.js";
import {
  createJsonSmdtoNodeEdges,
  NO_TRANSITION_EVENT_NAME,
} from "../../../utils/jsonUtils";
import { useNavigate } from "react-router-dom";
import BotIcon from "../../../pages/Images/bot_image.png";
import styles from "./BotCard.module.scss";
import CommonButton from "../Button/CommonButton.js";
import Redirect from "../../../pages/Images/redirect.png";
import Copy from "../../../pages/Images/copy.png";
import Checked from "../../../pages/Images/checked.png";
import { ROUTES } from "../../../utils/constant.js";
import { useDispatch } from "react-redux";
import { deleteBot, reloadBot, updateBot } from "../../../routines.js";
import { DeleteDialogBox } from "../../DeleteDialogBox/DeleteDialogBox.js";
import { DialogModal } from "../../Modal/DialogModal.js";
import { Init } from "../../Init/Init.js";

export const BotCard = ({ data, onTest, index, disableHoverEffect }) => {
  const dispatch = useDispatch();
  const flowContext = useContext(FlowContext);
  const navigate = useNavigate();
  const [anchorElVersions, setAnchorElVersions] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openReload, setOpenReload] = useState(false);
  const [copied, setCopied] = useState(false);
  const [openInitDialog, setOpenInitDialog] = useState(false);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenVersionsMenu = (event) => {
    setAnchorElVersions(event.currentTarget)
  }
  const handleCloseVersionsMenu = () => {
    setAnchorElVersions(null)
    handleCloseUserMenu();
  }

  const handleDeleteDialogOpen = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const editHandler = () => {
    localStorage.setItem("flowData", JSON.stringify({ smd: data.smd, smdId: data._id, smdName: data.smdName, platform: data.platform, }));
    navigate(`${ROUTES.BOT_FLOW}/${data._id}`, {
      state: {
        canUpdate: !data.editDisabled,
        name: data.smdName,
        description: data.description,
        type: "Web Bot",
        avatar: data.avatar,
        isEdit: true,
        smdId: data._id,
        smdVersions: data.smdVersions,
        currentSmdVersion: data.currentSmdVersion
      }
    });
  };

  const handleChangeSmdVersion = async (smd, selectedVersion) => {
    const payload = {
      smd_id: smd,
      smdVersion: selectedVersion,
    };
    dispatch(updateBot.trigger(payload));
    handleCloseVersionsMenu();
  };

  const cloneHandler = () => {
    localStorage.setItem("flowData", JSON.stringify({ smd: data.smd, platform: data.platform, }));
    const { nodes, edges } = createJsonSmdtoNodeEdges(data.smd);
    flowContext.setFlowData({
      nodes: nodes,
      edges: edges,
      platform: data.platform,
    });
    setOpenInitDialog(true)
  }

  const smdVersions = useMemo(() => {
    const versions = data.smdVersions?.map((version, i) => {
      return {
        label: `v: ${i + 1}.0`,
        value: version,
      };
    });

    return versions || []
  }, [data.smdVersions]);

  const options = useMemo(() => {
    const options = [
      {
        name: "Delete",
        icon: <DeleteForeverIcon fontSize="small" />,
        onClick: handleDeleteDialogOpen,
      },
      // {
      //   name: "Clone",
      //   icon: <SmartToyIcon fontSize="small" />,
      //   onClick: cloneHandler,
      // },
    ];

    if (data.editDisabled) {
      options.unshift({
        name: "View",
        icon: <VueIcon fontSize="small" />,
        onClick: editHandler,
      });
    } else {
      options.unshift({
        name: "Edit",
        icon: <EditIcon fontSize="small" />,
        onClick: editHandler,
      });

    }

    if (smdVersions.length > 1) {
      options.push({
        name: "Version",
        icon: <RightArrowIcon fontSize="small" />,
        onClick: handleOpenVersionsMenu,
      });
    }

    return options;
  }, [smdVersions, data]);

  return (
    <>
      <Card
        sx={{
          p: 1,
          background: "#FFFFFF",
          boxShadow: "5px 5px 10px #089BAE33",
          opacity: 1,
          border: "1px solid",
          borderColor: "#C8C8C8",
          minWidth: 230,
          maxWidth: 600,
          height: 140,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.3s ease",
          "&: hover": disableHoverEffect ? {} : {
            boxShadow: "5px 5px 10px #089BAE33",
            border: "0.5px solid #089BAE80",
          },
        }}
        key={index}
      >
        <Typography
          component={"div"}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Typography
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              height: "100%",
              width: "100%",
              gap: 1,
            }}
          >
            <CardMedia
              component="img"
              image={data.avatar}
              alt="Paella dish"
              sx={{
                borderRadius: "50%",
                height: "70px",
                width: "70px",
              }}
            />
            <Typography
              component={"div"}
              sx={{
                height: "100%",
                //width: '100%',
                display: "flex",
                flexDirection: "column",
                width: `calc(100% - 78px)`,
              }}
            >
              <Typography component={"div"} className={styles.botName}>
                {data.smdName}
                <div className="flex items-center">
                  <div className="text-xs font-semibold rounded-lg bg-black mr-2 px-2 py-1 text-white">
                    V: {data.smdVersions?.indexOf(data.currentSmdVersion) + 1 || 1}.0
                  </div>
                  <IconButton
                    aria-label="settings"
                    onClick={handleOpenUserMenu}
                    sx={{ padding: "0px", width: 0 }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorElVersions}
                    open={!!anchorElVersions}
                    onClose={handleCloseVersionsMenu}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    sx={{
                      mr: -10,
                      left: '70px'
                    }}
                  >
                    {smdVersions.map((option) => (
                      <div class="flex items-center px-2" onClick={() => handleChangeSmdVersion(data._id, option.value)}>
                        <input checked={option.value === (data.currentSmdVersion || "1")} id={option.label} type="radio" name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300" />
                        <label for={option.label} class="ms-2 text-xs font-semibold text-gray-800">{option.label}</label>
                      </div>
                    ))}
                  </Menu>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorElUser}
                    open={!!anchorElUser}
                    onClose={handleCloseUserMenu}
                    PaperProps={{
                      style: {
                        width: "16ch",
                        scrollY: "none",
                      },
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mr: -1,
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    sx={{
                      mr: -1.5,
                      ml: -1,
                    }}
                  >
                    {options.map((option) => (
                      <>
                        <MenuItem
                          key={option}
                          onClick={(e) => {
                            if (option.name !== 'Version') handleCloseUserMenu();
                            option.onClick(e);
                          }}
                          sx={{ pt: 0.3, pb: 0.3 }}
                          dense={false}
                        >
                          <ListItemIcon>{option.icon} </ListItemIcon>
                          <Typography>{option.name}</Typography>
                        </MenuItem>
                      </>
                    ))}
                  </Menu>
                </div>
              </Typography>
              <Typography
                variant="body"
                component={"div"}
                className={styles.description}
              >
                {data.description}
              </Typography>
            </Typography>
          </Typography>
          <div className={styles.bottomContainer}>
            {!Boolean(process.env.REACT_APP_DISABLED_TEST_BOT) && <div
              className={styles.copyButton}
              onClick={() => {
                const copyTxt = `${window.location.origin}${ROUTES.BOT}?smdId=${data._id}`;
                copyContent(copyTxt);
                setCopied(true);

                setTimeout(() => {
                  setCopied(false);
                }, 5000);
              }}
            >
              <img src={copied ? Checked : Copy}></img>
            </div>}
            {!Boolean(process.env.REACT_APP_DISABLED_TEST_BOT) && <div
              className={styles.copyButton}
              onClick={() => {
                const redirectUrl = `${window.location.origin}${ROUTES.BOT}?smdId=${data._id}`;
                window.open(redirectUrl, "_blank"); // Replace 'https://example.com' with the URL you want to redirect to
              }}
            >
              <img src={Redirect}></img>
            </div>}
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                setOpenReload(true)
              }}
              sx={{
                textTransform: "none",
                pl: 4,
                pr: 4,
                borderRadius: 1,
                background: "#089BAE",
                "&:hover": {
                  background: "#148695",
                },
              }}
            >
              Reload Bot
            </Button>
          </div>
        </Typography>
      </Card>
      <DeleteDialogBox
        open={openDeleteDialog}
        onClose={handleDeleteDialogClose}
        onDelete={() => {
          dispatch(deleteBot.trigger({ smd_id: data._id }));
          handleDeleteDialogClose();
        }}
      />
      <DeleteDialogBox
        open={openReload}
        onClose={() => { setOpenReload(false) }}
        onDelete={() => {
          dispatch(reloadBot.trigger())
          setOpenReload(false);
        }}
        message={<span>Are you sure you want to reload the bot?<br />
          It may affect ongoing conversations.</span>}
      />
      <DialogModal open={openInitDialog} onClose={() => { setOpenInitDialog(false) }} visibleBackdrop>
        <Init onClose={() => { setOpenInitDialog(false) }} isEdit />
      </DialogModal>

    </>
  );
};
