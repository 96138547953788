import { Typography } from "@material-ui/core";
import { height } from "@mui/system";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import './style.scss'
import Spinner from "../Spinner";

const AreaChart = ({ chartData, loading, height }) => {
  const [seriesData, setSeriesData] = useState([]);
  const [xAxisCategories, setXAxisCategories] = useState([]);
  const [yaxisTicks, setYaxisTicks] = useState([]);

  useEffect(() => {
    setSeriesData([
      {
        name: chartData.seriseName,
        data: chartData?.data,
      },
    ]);
    setXAxisCategories(chartData.categories);
    setYaxisTicks(chartData.yaxisTicks);
  }, [chartData]);

  const options = {
    chart: {
      height: 350,
      type: "area",
      zoom: false,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#089BAE"],
    },
    fill: {
      colors: ["#089BAE", "#2196F3"],
    },
    xaxis: {
      categories: xAxisCategories,
      scrollbar: {
        enabled: true, // Enable X-axis scrollbar
      },
      labels: {
        hideOverlappingLabels: true,
      },
      tickPlacement: 'between'

    },
    yaxis: {
      tickAmount: yaxisTicks?.length,
      categories: yaxisTicks,
      labels: {
        formatter: (value) => {
          return value;
        },
      },
      tickPositions: yaxisTicks,
    },
  };

  return (
    <Typography component={"div"} className="h-full">
      {loading && <div className="h-full w-full flex items-center justify-center"><Spinner text="Loading..." /></div>}
      {!loading && <ReactApexChart
        options={options}
        series={seriesData}
        type="area"
        height={height}
        className="chart-container"
      />}
    </Typography>
  );
};

export default AreaChart;
