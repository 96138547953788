import { Button } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import styles from './BackToTopButton.module.scss';


export const BackToTopButton = ({chatContainerRef}) => {
    const backToTopButtonRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current && chatContainerRef.current.scrollTop > 100) {
        backToTopButtonRef.current.style.display = 'inline-block'; // Updated line
      } else {
        backToTopButtonRef.current.style.display = 'none';
      }
    };
    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  
  const scrollUp = () => {
    chatContainerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  return (
    <div className={styles.btnContainer}>
        <Button className={styles.backToTopBtn} onClick={scrollUp} ref={backToTopButtonRef}  style={{ display: 'none' }}>back to top</Button>
    </div> 
  );
}