import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import {
  Button,
  InputAdornment,
  OutlinedInput,
  IconButton,
  InputLabel,
  FormControl,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
//import CssBaseline from '@material-ui/core/CssBaseline';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import axios from "../../api/axios";
import { endpoints } from "../../api/endpoints";
import { useAuth } from "../../utils/auth";
import { emailValidation } from "../../utils/helper";
import BotImage from "../Images/bot_image.png";
import HeaderIcon from "../Images/headerIcon.png";
import ChatIcon from "../Images/chatIcon.png";

import styles from "./SignIn.module.scss";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import { ROUTES } from "../../utils/constant";
import { loginUsers } from "../../routines";
const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  signUpTypography: {
    color: theme.palette.secondary.main,
  },
}));

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [currentPage, setCurrentPage] = useState("login");
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEmailChange = (event) => {
    setUserName(event.target.value);
    setIsValidEmail(emailValidation(event.target.value));
  };

  const toggleCurrentPage = () => {
    if (currentPage === "login") {
      setCurrentPage("forget");
    } else {
      setCurrentPage("login");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentPage === "login") {
      if (!isValidEmail) {
        toast.error("Enter Valid email");
        return;
      }
      const credentials = {
        email: username,
        password,
      };
      dispatch(loginUsers.trigger(credentials));
    } else {
      forgetPassword(e);
    }
  };

  const forgetPassword = async (e) => {
    e.preventDefault();
    axios
      .post("/forgetPassword", { email: username })
      .then((res) => {
        toast.success(res.data);
      })
      .catch((err) => {
        toast.error(err.response?.data);
      });
  };

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  });

  // Inspired by blueprintjs
  function BpCheckbox(props) {
    return (
      <Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  }

  return (
    <>
      <div className={styles.Container}>
        <div className={styles.mainContainer}>
          <div className={styles.formContainer}>
            <>
              <div className={styles.login_mainContainer}>
                <div className={styles.upperSection}>
                  <div className={styles.botifier}>
                    <div className={styles.botifierDiv}>
                      {" "}
                      <img src={HeaderIcon} className={styles.headerIcon}></img>
                      Botifier
                    </div>
                  </div>
                  <div className={styles.welcomeAndIconContainer}>
                    <div className={styles.welcomeContainer}>
                    {currentPage === "login" ? (
                      <div className={styles.welcomeHeader}>Welcome</div>
                    ) : (
                      <div className={styles.welcomeHeader}>
                        Reset your password{" "}
                      </div>
                    )}
                    {currentPage === "login" && (
                      <div className={styles.login_Detail}>
                        Please enter your details to Login
                      </div>
                    )}
                    </div>
                    <div className={styles.chatIconContainer}>
                      <img src={ChatIcon}></img>
                    </div>
                  </div>
                </div>

                <form
                  className={styles.form}
                  noValidate
                  onSubmit={() => {
                    handleSubmit();
                  }}
                >
                  <div className={styles.formControlContainer}>
                    <FormControl
                      sx={{ mt: 2, width: "100%", mb: 3 }}
                      variant="outlined"
                      className={styles.Inputlable}
                    >
                      <InputLabel
                        htmlFor="outlined-adornment-password"
                        className={styles.customInputLabel}
                        sx={{
                          "&.Mui-focused": {
                            color: "#089BAE",
                          },
                        }}
                      >
                        Email Address
                      </InputLabel>
                      <OutlinedInput
                        required
                        fullWidth
                        id="email"
                        type="text"
                        label="Email Address*"
                        className={styles.userNameInput}
                        onChange={handleEmailChange}
                        error={!isValidEmail}
                        sx={{
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#089BAE",
                          },
                        }}
                      />
                    </FormControl>
                  </div>
                  {currentPage === "login" && (
                    <div className={styles.formControlContainer}>
                      <FormControl sx={{ width: "100%", mb: "10px" }}>
                        <InputLabel
                          htmlFor="outlined-adornment-password"
                          className={styles.customInputLabel}
                          sx={{
                            "&.Mui-focused": {
                              color: "#089BAE",
                            },
                          }}
                        >
                          Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          className={styles.userNameInput}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          sx={{
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#089BAE",
                            },
                          }}
                        />
                      </FormControl>
                    </div>
                  )}

                  {currentPage === "login" && (
                    <div className={styles.forgotPassword}>
                      {/* <div>
                        <BpCheckbox sx={{ p: 0, mr: 1 }} />
                        <span className={styles.checkBox}>remember me</span>
                      </div> */}
                      <span
                        className={styles.forgetButton}
                        onClick={() => {
                          toggleCurrentPage();
                          // setUserName("");
                        }}
                      >
                        forgot password ?
                      </span>
                    </div>
                  )}
                  <div className={styles.buttonContainer}>
                    <Button
                      sx={{ textTransform: "none" }}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      {currentPage === "login" ? "Sign In" : "Submit"}
                    </Button>
                  </div>
                  <div>
                    {currentPage === "login" && (
                      <span className={styles.have_account}>
                        Don't have a account?
                      </span>
                    )}
                    <span
                      className={styles.click_here}
                      onClick={() => {
                        if (currentPage === "login") {
                          navigate(ROUTES.SIGN_UP);
                        } else {
                          toggleCurrentPage();
                        }
                      }}
                    >
                      {`Click here to ${
                        currentPage === "login" ? "Sign Up" : "Sign In"
                      }`}
                    </span>
                  </div>
                </form>
              </div>
            </>
          </div>
          <img src={BotImage} className={styles.bg_image}></img>
        </div>
      </div>
    </>
  );
};

export default SignIn;
