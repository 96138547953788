import { Button, Typography } from "@mui/material";
import { DialogModal } from "../Modal/DialogModal.js";
import { theme } from "./../../utils/theme.js";

export const DeleteDialogBox = ({
  onDelete,
  open,
  onClose,
  isHeader,
  icon,
  title,
  message,
}) => {
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      isHeader={isHeader}
      icon={icon}
      title={title}
    >
      <Typography component={"div"} sx={{ pt: 5, pl: 6, pr: 6,pb: 3.4 }}>
        <Typography
          component={"div"}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            // color: theme.palette.primary.main,
            font: "normal normal 600 20px/27px 'Segoe UI'",
            letterSpacing: "0px",
            color: "#3E3E3E"
          }}
        >
          {!!message? message:<span>Are you sure <br /> you want to delete it?</span>}
        </Typography>

        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1.5,
            pt: "30px",
          }}
          component={"div"}
        >
          <Button
            sx={{
              // backgroundColor: theme.palette.primary.main,
              // px: 7,
              // py: 0.9
              textTransform: "capitalize",
              font: "normal normal 600 14px/19px 'Segoe UI'",
              color: "#FFFFFF",
              background: "#089BAE",
              borderRadius: "5px",
              px: "68px",
              py: "9px",
              "&:hover": {
                background: "#148695",
              },
            }}
            size="medium"
            variant="contained"
            onClick={onClose}
          >
            No
          </Button>
          <Button
            
            sx={{
              // backgroundColor: theme.palette.primary.main,
              font: "normal normal 600 14px/19px 'Segoe UI'",
              color: "#FFFFFF",
              background: "#089BAE",
              borderRadius: "5px",
              width: "fit-content",
              px: "68px",
              py: "9px",
              ":hover": {
                backgroundColor: "#CC0000",
              },
            }}
            size="medium"
            variant="contained"
            onClick={onDelete}
            autoFocus
          >
            Yes
          </Button>
        </Typography>
      </Typography>
    </DialogModal>
  );
};
