import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { updateUserDetails, updatePassword } from "../../routines";
import { connect, useDispatch } from "react-redux";
import { profileImgPlaceholder } from "../../utils/constant";
import { regularCharValidation } from "../../utils/helper";

const EditProfile = ({ user, profileImg }) => {
  const [formDetails, setFormDetails] = useState({});
  const [editableField, setEditableField] = useState(null);
  const [formValidFields, setFormValidFields] = useState({
    firstName: true,
    lastName: true,
    designation: true
  })
  const dispatch = useDispatch();

  useEffect(() => {
    setFormDetails({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      designation: user.designation,
    });
  }, [user]);

  const updateForm = (e) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.name === "designation" ? e.target.value.replace(/\s{2,}/g, ' ').trimStart() : e.target.value.trim() });
    setFormValidFields({ ...formValidFields, [e.target.name]: regularCharValidation(e.target.value.replace(/\s/g, "")) })
  };

  const toggleFieldEdit = (fieldName) => {
    if (editableField === fieldName) {
      setEditableField(null);
    } else {
      setEditableField(fieldName);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    for (const key in formDetails) {
      if (key !== 'designation') {
        if (formDetails[key].trim() === "") {
          toast.error("Input fields cannot be empty.");
          return;
        }
      }
    }
    for (const key in formValidFields) {
      if (key !== 'designation') {
        if (!formValidFields[key]) {
          toast.error("Input fields should contain only letters.");
          return;
        }
      }
    }
    if (
      profileImg &&
      profileImg !== profileImgPlaceholder &&
      profileImg !== user.profileImg
    ) {
      formDetails.profileImg = profileImg;
    }

    dispatch(updateUserDetails.trigger(formDetails));
  };

  const updateUserPassword = async (e) => {
    e.preventDefault();
    dispatch(updatePassword.trigger({ email: user.email }));
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <Container component="main" fullWidth>
      <Typography component="div" sx={{ display: "flex", flexDirection: "column" }}>
        <FormControl>
          <Grid container spacing={2} marginY={1}>
            {Object.keys(formDetails).map((fieldName) => (
              <Grid item xs={12} sm={6} key={fieldName}>
                <Typography
                  variant="p"
                  component={"div"}
                  sx={{
                    my: 1,
                    font: "normal normal 600 14px/17px 'Graphie, SemiBold'",
                    letterSpacing: "0px",
                    color: "#3E3E3E",
                  }}
                >
                  {capitalize(
                    fieldName === "firstName"
                      ? "First Name"
                      : fieldName === "lastName"
                        ? "Last Name"
                        : fieldName
                  )}
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name={fieldName}
                  value={formDetails[fieldName]}
                  onChange={updateForm}
                  onBlur={(e) => {
                    setFormDetails({ ...formDetails, [e.target.name]: e.target.value.trim(), })
                    setEditableField(null)
                  }}
                  disabled={editableField !== fieldName}
                  error={!formValidFields[fieldName]}
                  InputProps={
                    fieldName !== "email"
                      ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => toggleFieldEdit(fieldName)}
                            >
                              <EditIcon
                                sx={{
                                  fontSize: "1.2rem",
                                  color:
                                    editableField === fieldName
                                      ? "#148695"
                                      : "#000000",
                                }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }
                      : undefined
                  }
                  sx={{
                    backgroundColor: "#F8F8F8",
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#148695",
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                height: "40px",
                color: "#089BAE",
                borderColor: "#089BAE",
                borderRadius: "5px",
                py: "8px",
                "&:hover": {
                  borderColor: "#089BAE",
                },
              }}
              onClick={updateUserPassword}
            >
              Reset Password
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                alignSelf: "center",
                height: "40px",
                maxWidth: "140px",
                background: "#089BAE 0% 0% no-repeat padding-box",
                borderRadius: "5px",
                py: "8px",
                m: "16px",
                "&:hover": {
                  background: "#148695",
                },
              }}
              onClick={handleSubmit}
            >
              Save Changes
            </Button>
          </Grid>
        </FormControl>
      </Typography>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(EditProfile);
