import { useContext } from "react";
import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createJsonSmdtoNodeEdges } from "../../utils/jsonUtils";
import { ROUTES } from "../../utils/constant";
import { FlowContext } from "../../store/flow-context";

const BotTemplateCard = ({ data, onClick, index }) => {
  const flowContext = useContext(FlowContext);
  const navigate = useNavigate();

  const editHandler = () => {
    localStorage.setItem("flowData", JSON.stringify(data.smd));
    const { nodes, edges } = createJsonSmdtoNodeEdges(data.smd);
    flowContext.setFlowData({
      nodes: nodes,
      edges: edges,
    });
    navigate(ROUTES.BOT_FLOW, {
      state: {
        name: data.smdName,
        description: data.description,
        type: "Web Bot",
        avatar: data.avatar,
      },
    });
  };

  const onSelectTemplates = () => {
    navigate(ROUTES.CREATE_BOT_FLOW, {
      state: {
        isPreview: true,
        name: data.smdName,
        description: data.description,
        avatar: data.avatar,
        isEdit: true,
      },
    });
    localStorage.setItem("flowData", JSON.stringify({ smd: data.smd }));
    const { nodes, edges } = createJsonSmdtoNodeEdges(data.smd);
    flowContext.setFlowData({
      nodes: nodes,
      edges: edges,
    });
    onClick();
  };

  return (
    <Card
      sx={{
        background: "#FFFFFF",
        boxShadow: "5px 5px 10px #089BAE33",
        opacity: 1,
        borderColor: "#C8C8C8",
        minWidth: 200,
        maxWidth: 220,
        height: 200,
        transition: "box-shadow 0.2s ease",
        "&:hover": {
          boxShadow: "5px 5px 10px #089BAE33",
          border: "0.5px solid #089BAE80",
          cursor: "pointer",
        },
      }}
      key={index}
      className="botTemplatesCard"
      onClick={onSelectTemplates}
    >
      <CardMedia
        component="img"
        image={data.avatar}
        alt="Bot Image"
        sx={{
          height: "80px",
          width: "100%",
          aspectRatio: "3/4",
          objectFit: "cover",
        }}
      />
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          sx={{
            textTransform: "capitalize",
            color: "#089BAE",
          }}
        >
          {data.smdName}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{
            color: "#3E3E3E",
            opacity: 1,
            overflowWrap: "break-word",
            wordWrap: "normal",
            lineBreak: "anywhere",
            overflowY: "auto",
            scrollbarWidth: "thin",
          }}
        >
          This is the description of the bot template.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BotTemplateCard;
