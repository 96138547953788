import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import bannerImage from "../Images/banner_image.png";
import React, { useEffect, useState } from "react";
import { BasicSelect } from "../../components/Select/Select";
import { useParams, useLocation } from "react-router-dom";
import { getAllChats } from "../../api";
import { ChatsideWindow } from "../../components/ChatSideWindow/ChatSideWindow";
import { UsersList } from "../../components/UsersList/UsersList";
import { connect, useDispatch } from "react-redux";
import { DialogModal } from "../../components/Modal/DialogModal";
import "./style.scss";
import DatePicker from "../../components/DatePicker/Datepicker";
import { toast } from "react-toastify";
import { getCSVReport } from "../../routines";
import DashboardBanner from "../../components/Banner/DashboardBanner";
import moment from "moment";
import { setUserChatSelectedSmd } from "../../reducers/userChatSelectedSmd";
import { ROUTES } from "../../utils/constant";
import { useNavigate } from "react-router-dom";

const Chats = ({ bots, userChatSelectedSmd, setUserChatSelectedSmd }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uid } = useParams();
  const { state } = useLocation();
  const [smds, setSmds] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [tableView, setTableView] = useState(false);
  const [fetchingConversations, setFetchingConversations] = useState(true)
  const [conversation, setConversation] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isOpenDownloadDataModal, setIsOpenDownloadDataModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isCompletedModal, setIsCompletedModal] = useState(false);
  const [selectedSmdForReport, setSelectedSmdForReport] = useState("");

  const botId = state ? state.botId : "";

  const onSelectSmdForReport = (value) => {
    setSelectedSmdForReport(value);
  };
  const handleOpenDownloadDataModal = () => {
    setIsOpenDownloadDataModal(true);
  };
  const handleCloseDownloadDataModal = () => {
    if (isCompletedModal !== isCompleted) {
      setIsCompleted(isCompletedModal)
    }
    setIsOpenDownloadDataModal(false);
    setStartDate(null);
    setEndDate(null);
    setSelectedSmdForReport("");
    setIsOpenDownloadDataModal(false);
  };

  const handleIsCompletedModal = () => {
    setIsCompletedModal(!isCompletedModal)
  };

  const onStartDateChange = (startDate) => {
    if (!!endDate) {
      if (startDate <= endDate) {
        setStartDate(startDate);
      } else {
        toast.error("Please select the date comes before the end date");
      }
    } else {
      setStartDate(startDate);
    }
  };

  const onEndDateChange = (date) => {
    if (!!startDate) {
      if (startDate <= date) {
        setEndDate(date);
      } else {
        toast.error("Please select the date comes after the start date");
      }
    } else {
      setEndDate(date);
    }
  };

  const validateDownloadInputData = () => {
    if (!startDate) {
      toast.warning("Please select start date!");
      return false;
    }
    if (!endDate) {
      toast.warning("Please select end date!");
      return false;
    }
    if (selectedSmdForReport === "") {
      toast.warning("Please select your bot!");
      return false;
    }
    return true;
  };
  const onDownloadCSVReport = () => {
    if (validateDownloadInputData()) {
      const payload = {
        smdId: selectedSmdForReport,
        isCompleted: isCompletedModal,
        from: startDate.format("YYYY-MM-DD"),
        to: endDate.format("YYYY-MM-DD"),
      };
      dispatch(getCSVReport.trigger(payload));
      handleCloseDownloadDataModal();
    }
  };

  const handleChange = (event) => {
    tableView && setIsCompleted(false)
    setTableView(!tableView);
    setSearchText("");
  };

  const onSelectSmd = (value) => {
    setConversation([]);
    setUserChatSelectedSmd(value);
    setSearchText("");

    if (uid) {
      navigate(`${ROUTES.CHATS}`, {
        state: {
          botId: value,
        },
      });
    }
  };

  const fetchSmdChats = async (isCompleted, offset) => {
    if (!fetchingConversations) setFetchingConversations(true)
    
    const allChats = await getAllChats(
      userChatSelectedSmd,
      uid,
      isCompleted,
      offset,
      searchText
    );
    setFetchingConversations(false)
    setConversation(allChats?.userData);
    setRowCount(allChats?.totalRecords);
  };

  useEffect(() => {
    if (bots?.length > 0) {
      const smdList = bots.map((bot) => {
        return {
          isActive: bot.isActive,
          name: bot.smdName,
          id: bot._id,
          smd: bot.smd,
        };
      });
      setSmds(smdList);
      if (!!botId) {
        setUserChatSelectedSmd(botId);
      } else {
        // setUserChatSelectedSmd(bots[0]._id);
        userChatSelectedSmd? setUserChatSelectedSmd(userChatSelectedSmd): setUserChatSelectedSmd(bots[0]._id);
      }
    }
  }, [uid, bots]);

  useEffect(() => {
    try {
      if (userChatSelectedSmd) {
        setConversation([]);
        fetchSmdChats(isCompleted);
      }
    } catch (error) {
      console.log("Error occurred while finding Users for the bot");
    }
  }, [userChatSelectedSmd, uid, searchText, isCompleted]);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#089bae",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#089bae",
    },
  }));

  useEffect(() => {
    setIsCompletedModal(isCompleted)
  }, [isCompleted])

  return (
    <Typography component={"div"} sx={{ width: "100%" }}>
      <DashboardBanner image={bannerImage} imageWidth={40} imageHegiht={160}>
        <Typography
          sx={{
            height: 160,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <h5>Select Bot</h5>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              textShadow: "0px 1px 2px rgba(0, 0, 0, 0.3)", // Text shadow for a subtle effect
            }}
          >
            Botifier's AI-powered bot builder to easily create bots and
            conversational workflows.
          </Typography>
          <Typography component={"div"} sx={{ maxWidth: "320px" }}>
            <BasicSelect
              items={smds}
              onSelect={onSelectSmd}
              selected={userChatSelectedSmd}
              title={"Bots"}
              label={"Bots"}
            />
          </Typography>
        </Typography>
      </DashboardBanner>

      <Typography
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography
          component={"div"}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mb: 1,
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              height: "30px",
              background: "#089BAE 0% 0% no-repeat padding-box",
              borderRadius: "5px",
              py: "8px",
              "&:hover": {
                background: "#148695",
              },
            }}
            onClick={handleOpenDownloadDataModal}
          >
            Download Data
          </Button>
          {/* <AntSwitch */}
          {/*   defaultChecked={false} */}
          {/*   onChange={handleChange} */}
          {/*   checked={tableView} */}
          {/*   inputProps={{ "aria-label": "ant design" }} */}
          {/*   size="small" */}
          {/* /> */}
          {/* Table View */}
        </Typography>
        {tableView ? (
          <UsersList
            conversation={conversation}
            fetchSmdChats={fetchSmdChats}
            rowCount={rowCount}
            isCompleted={isCompleted}
            setIsCompleted={setIsCompleted}
          />
        ) : (
          <ChatsideWindow
            conversation={conversation}
            fetchingConversations={fetchingConversations}
            fetchSmdChats={fetchSmdChats}
            rowCount={rowCount}
            searchText={searchText}
            setSearchText={setSearchText}
            isCompleted={isCompleted}
            setIsCompleted={setIsCompleted}
          />
        )}
      </Typography>
      <DialogModal
        visibleBackdrop
        open={isOpenDownloadDataModal}
        onClose={handleCloseDownloadDataModal}
      >
        <Typography component={"div"} className="downloadModalContainer">
          <Typography component={"div"} className="datePickerContainer">
            <Typography component={"div"} className="datePicker">
              <strong>From</strong>
              <DatePicker
                date={startDate}
                noBorder
                placeholder={"Start Date"}
                readOnly
                onDateChange={onStartDateChange}
                maxDate={moment()}
                anchorDirection="left"
              />
            </Typography>
            <Typography component={"div"} className="datePicker">
              <strong>To</strong>
              <DatePicker
                date={endDate}
                noBorder
                placeholder={"End Date"}
                readOnly
                onDateChange={onEndDateChange}
                anchorDirection="right"
                horizontalMargin={35}
                maxDate={moment()}
              />
            </Typography>
          </Typography>
          <Typography component={"div"} className="datePickerContainer">
            <BasicSelect
              items={smds}
              size="small"
              width={200}
              onSelect={onSelectSmdForReport}
              selected={selectedSmdForReport}
              title={"Bots"}
              label={"Bots"}
            />

            <Typography component={"div"} className="completedContainer">
              <strong>Completed Only </strong>
              <AntSwitch
                defaultChecked={false}
                onChange={handleIsCompletedModal}
                checked={isCompletedModal}
                inputProps={{ "aria-label": "ant design" }}
              />
            </Typography>
          </Typography>

          <Typography component={"div"} className="datePickerContainer">
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                height: 35,
                background: "#C8C8C8",
                borderRadius: "5px",
                color: "black",
                width: 130,
                px: "8px",
                "&:hover": {
                  background: "#a09e9ece",
                },
              }}
              onClick={handleCloseDownloadDataModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                height: 35,
                width: 130,
                background: "#089BAE 0% 0% no-repeat padding-box",
                borderRadius: "5px",
                py: "8px",
                "&:hover": {
                  background: "#148695",
                },
              }}
              onClick={onDownloadCSVReport}
            >
              Download
            </Button>
          </Typography>
        </Typography>
      </DialogModal>
    </Typography>
  );
};

const mapStateToProps = (state) => {
  return {
    bots: state.bots,
    userChatSelectedSmd: state.userChatSelectedSmd,
  };
};

const mapDispatchToProps = {
  setUserChatSelectedSmd,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chats);
