import React, { useState, useEffect, useCallback, useRef } from "react";
import { AddonDetailsForm } from "../../AddonDetailsForm/AddonDetailsForm";
import AddonDetailsActivatedBots from "../../AddonDetailsActivatedBots/AddonDetailsActivatedBots";
import AddonDetailsActivationPanel from "../../AddonDetailsActivationPanel/AddonDetailsActivationPanel";
import { useSearchParams } from "react-router-dom";
import { sfLoginUrl } from "../../../utils/constant";
import NodeMapper from "../../NodeMapper/NodeMapper";
import { BasicSelect } from "../../Select/Select";
import { Typography } from "@mui/material";
import { Apis } from "../../../api";
import { toast } from "react-toastify";

const SalesforceAddonDetails = ({
  deactivateAddon,
  isConfigure,
  inputList,
  smdList,
  selectedSmd,
  inputs,
  setInputs,
  isBotSelect,
  openDetailForm,
  addonData,
  handleDeactivateAllConfirmation,
  activatedSmd,
  commonHandleOpenDetailForm,
  commonOnSubmit,
  isValid,
  setIsValid,
  commonValidate,
  errorMessage,
  commonDeactivateAllAddon,
  isDeactivateAllConfirmation,
  commonOnSelectSmd,
  setErrorMessage,
  setOpenDetailForm,
  allSmd,
  setSelectedSmd,
  setIsBotSelect,
  setSmdList,
}) => {
  const [sfObject, setSfObject] = useState({});
  const [sfObjectNames, setSfObjectNames] = useState({});
  const [selectedBotNodes, setSelectedBotNodes] = useState([]);
  const [selectedSfObject, setSelectedSfObject] = useState("");
  const [mappings, setMappings] = useState([{ node: "", objectKey: "" }]);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const sfCredCallLimit = 10;
  const sfInterval = 1000;
  // let sfCredCallCounter = 0;
  // let sfIntervalId;
  const intervalRef = useRef(1000);
  const sfCredCallCounter = useRef(0)



  const handleOpenDetailForm = useCallback(() => {
    if (openDetailForm) {
      setSelectedSfObject("");
      setSelectedBotNodes([]);
      setMappings([{ node: "", objectKey: "" }]);
    }
    commonHandleOpenDetailForm();
  }, [openDetailForm, commonHandleOpenDetailForm]);

  const validate = () => {
    if (commonValidate()) {
      if (!validateMappingAddon()) {
        return;
      } else {
        addMappingDataToInputs();
        setIsReadyToSubmit(true);
      }
    }
  };

  const addMappingDataToInputs = () => {
    const mappingObj = mappings.map((item) => ({
      [item.objectKey]: item.node,
    })); // map object to fit backend demands
    setInputs((prevInputs) => {
      prevInputs['mapping']['value'] = JSON.stringify(mappingObj);
      prevInputs['objectName']['value'] = sfObject[selectedSfObject - 1].customObject;
      return { ...prevInputs };
    });
  };

  const isMappingsValid = () => {
    if (mappings.length === 0) {
      return false;
    }

    for (const mapping of mappings) {
      if (!mapping.node || !mapping.objectKey) {
        return false;
      }
    }

    return true;
  };

  const validateMappingAddon = () => {
    if (!selectedSfObject) {
      setErrorMessage("Please select an object");
      setIsValid(false);
      return false;
    }
    if (!isMappingsValid()) {
      setErrorMessage("Please fill in all mapping inputs");
      setIsValid(false);
      return false;
    }

    return true;
  };

  const handleActivateSalesforce = () => {
    const sfCreds = JSON.parse(localStorage.getItem("sfCreds"));
    if (!sfCreds) {
      handleSalesforceLogin();
    } else {
      handleOpenDetailForm();
    }
  };

  const handleSalesforceLogin = async (editedSmdId = "") => {
    const authToken = localStorage.getItem("authToken");
    const stateObj = {
      authToken: authToken,
      editedSmdId: editedSmdId,
    };
    const state = JSON.stringify(stateObj);
    window.location.href = `${sfLoginUrl}&state=${state}`;
  };

  

  

  const displayEditData = useCallback((smd) => {
    const sfAddonData = smd.platform.salesforce;
    const selectedSfObjectName = sfAddonData.objectName;
    setSelectedSfObject(
      sfObjectNames.find((obj) => obj.name === selectedSfObjectName).id
    );
    const selectBotMappingObj = JSON.parse(sfAddonData.mapping);
    const selectBotMapping = selectBotMappingObj.map((obj) => {
      return { node: obj[Object.keys(obj)[0]], objectKey: Object.keys(obj)[0] };
    });
    setMappings(selectBotMapping);
    setSelectedSmd(smd._id);
    setIsBotSelect(true);
    setSmdList([{ id: smd._id, name: smd.smdName }]);
  }, [setIsBotSelect, setSelectedSmd, setSmdList, sfObjectNames]);

  const handleEdit = (smd) => {
    const sfCreds = JSON.parse(localStorage.getItem("sfCreds"));
    if (!sfCreds) {
      handleSalesforceLogin(smd._id);
    } else {
      displayEditData(smd);
      setOpenDetailForm(true)
    }
  };

  useEffect(() => {
    try {
      const updateSfData = () => {
        const sfCreds = JSON.parse(localStorage.getItem("sfCreds"));
        if (sfCreds) {
          setInputs({
            ...addonData.input,
            accessToken: {
              ...addonData.input.accessToken,
              value: sfCreds.accessToken,
            },
            customDomain: {
              ...addonData.input.customDomain,
              value: sfCreds.customDomain,
            },
            refreshToken: {
              ...addonData.input.refreshToken,
              value: sfCreds.refreshToken,
            },
            salesforceCode: {
              ...addonData.input.salesforceCode,
              value: sfCreds.salesforceCode,
            },
          });
          const noOwnerIdObj = sfCreds.ObjectData.map((item) => ({
            customObject: item.customObject,
            customFields: item.customFields.filter((field) => field !== "OwnerId"),
          }));
          setSfObject(noOwnerIdObj);
          setSfObjectNames(
            noOwnerIdObj.map((obj, index) => ({
              name: obj.customObject,
              id: index + 1,
            }))
          );
        }
      };

      const fetchSalesforceCallbackData = async () => {
        sfCredCallCounter.current++;
        const response = await Apis.getSalesforceCreds();
        if (!!response?.data) {
          localStorage.setItem("sfCreds", JSON.stringify(response.data));
          updateSfData();
          const intervalId = intervalRef.current
          clearInterval(intervalId)
        }
        if (sfCredCallCounter.current >= sfCredCallLimit) {
          const intervalId = intervalRef.current
          clearInterval(intervalId)
        }
      };

      if (!!addonData) {
        const sfCreds = JSON.parse(localStorage.getItem("sfCreds"));
        if (!sfCreds) {
          const sfIntervalId = setInterval(fetchSalesforceCallbackData, sfInterval);
          intervalRef.current = sfIntervalId
        } else {
          updateSfData();
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [addonData, setInputs]);

  useEffect(() => {
    if (!!sfObjectNames?.length) {
      const wasAcativatedNow = searchParams.get("wasActivatedNow");
      const editedSmdId = searchParams.get("editedSmdId");
      if (!!editedSmdId) {
        const editedSmd = addonData.smd.find((smd) => smd._id === editedSmdId);
        displayEditData(editedSmd);
      }
      if (wasAcativatedNow) {
        searchParams.delete("wasActivatedNow");
        searchParams.delete("editedSmdId");
        window.history.replaceState({}, document.title, searchParams);
        setSearchParams(searchParams);
        setOpenDetailForm(true);
      }
    }
  }, [
    searchParams,
    sfObjectNames,
    setOpenDetailForm,
    setSearchParams,
    addonData,
    displayEditData,
  ]);

  useEffect(() => {
    if (!!selectedSmd) {
      const selectedBotSmd = allSmd.find((item) => item.id === selectedSmd);
      if (selectedBotSmd) {
        const smdConfig = JSON.parse(selectedBotSmd.smd);
        const nodes = Object.getOwnPropertyNames(smdConfig?.messages);
        setSelectedBotNodes(nodes);
      }
    }
  }, [selectedSmd, allSmd]);

  useEffect(() => {
    const onSubmit = async () => {
      try {
        await commonOnSubmit();
        handleOpenDetailForm();
      } catch (error) {
        toast.error("Error occured while trying to add salesforce object");
      }
    };
    
    if (isReadyToSubmit) {
      onSubmit();
      setIsReadyToSubmit(false);
    }
  }, [isReadyToSubmit, commonOnSubmit, handleOpenDetailForm]);

  useEffect(() => {
    const removeSfObject = () => {
      localStorage.removeItem("sfCreds");
    };

    window.addEventListener("beforeunload", removeSfObject);
    return () => {
      window.removeEventListener("beforeunload", removeSfObject);
      removeSfObject();
    };
  }, []);

  return (
    <div>
      <AddonDetailsActivationPanel
        addonData={addonData}
        handleDeactivateAllConfirmation={handleDeactivateAllConfirmation}
        handleOpenDetailForm={handleActivateSalesforce}
        isConfigure={isConfigure}
        deactivateAllAddon={commonDeactivateAllAddon}
        isDeactivateAllConfirmation={isDeactivateAllConfirmation}
      />
      <AddonDetailsActivatedBots
        activatedSmd={activatedSmd}
        deactivateAddon={deactivateAddon}
        handleEdit={handleEdit}
      />
      {openDetailForm && !addonData?.editOnFrontend && (
        <AddonDetailsForm
          open={openDetailForm}
          smdList={smdList}
          isBotSelect={isBotSelect}
          selectedSmd={selectedSmd}
          onClose={handleOpenDetailForm}
          inputList={inputList}
          onSelectSmd={commonOnSelectSmd}
          inputs={inputs}
          setInputs={setInputs}
          isValid={isValid}
          setIsValid={setIsValid}
          validate={validate}
          errorMessage={errorMessage}
          selectedBotNodes={selectedBotNodes}
          sfObjectNames={sfObjectNames}
          sfObject={sfObject}
        >
          <Typography component={"div"} sx={{ width: "100%", mb: "15px" }}>
              <BasicSelect
                width={"100%"}
                size="small"
                label={"Bots"}
                title="Bots"
                items={smdList}
                selected={selectedSmd}
                onSelect={commonOnSelectSmd}
                isDisabled={isBotSelect}
              />
            </Typography>
          {!!sfObjectNames?.length && (
            <Typography component={"div"} sx={{ width: "100%", mb: "15px" }}>
              <BasicSelect
                width={"100%"}
                size="small"
                label={"Object"}
                title="Object"
                items={sfObjectNames}
                selected={selectedSfObject}
                onSelect={(value) => {
                  setSelectedSfObject(value);
                }}
              />
            </Typography>
          )}
          {selectedSfObject && !!selectedBotNodes.length && (
            <NodeMapper
              nodes={selectedBotNodes}
              objectKeys={sfObject[selectedSfObject - 1].customFields}
              mappings={mappings}
              setMappings={setMappings}
            />
          )}
        </AddonDetailsForm>
      )}
    </div>
  );
};

export default SalesforceAddonDetails;
