import { handleActions } from "redux-actions";
import { getBotUses } from "../routines";

const defaultState = [];

export const botUsesReducer = handleActions(
  {
    [getBotUses.SUCCESS]: (state, { payload }) => {
      return [...payload];
    },
  },
  defaultState
);